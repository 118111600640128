// src/components/AddInviteeForm.js
import React, { useState } from "react";
import {
    Box,
    TextField,
    Typography,
    Button,
    Paper,
    IconButton,
    Fade,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const AddInviteeForm = ({ onSave, onCancel }) => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        const newInvitee = { name, phone };
        onSave(newInvitee);
    };

    return (
        <Fade in={true}>
            <Box
                sx={(theme) => ({
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: { xs: "90%", sm: 400 },
                    maxWidth: "100%",
                })}
            >
                <Paper
                    elevation={6}
                    sx={(theme) => ({
                        p: 4,
                        borderRadius: 3,
                        direction: "rtl",
                        position: "relative",
                        background: theme.palette.background.paper,
                        border: `1px solid ${theme.palette.primary.light}15`,
                        boxShadow: '0 8px 32px rgba(142, 111, 144, 0.12)',
                        transition: 'transform 0.2s ease-in-out',
                        '&:hover': {
                            transform: 'translateY(-4px)',
                        },
                    })}
                >
                    <IconButton
                        onClick={onCancel}
                        sx={(theme) => ({
                            position: "absolute",
                            left: 8,
                            top: 8,
                            color: theme.palette.text.secondary,
                            '&:hover': {
                                backgroundColor: `${theme.palette.primary.light}15`,
                                color: theme.palette.primary.main,
                            },
                        })}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2.5,
                        }}
                    >
                        <Box
                            sx={(theme) => ({
                                display: "flex",
                                alignItems: "center",
                                gap: 1.5,
                                mb: 2,
                            })}
                        >
                            <PersonAddIcon 
                                sx={(theme) => ({
                                    color: theme.palette.primary.main,
                                    fontSize: '2rem'
                                })}
                            />
                            <Typography 
                                variant="h5"
                                sx={(theme) => ({
                                    color: theme.palette.primary.main,
                                    fontWeight: 600,
                                })}
                            >
                                הוספת אורח חדש
                            </Typography>
                        </Box>

                        <TextField
                            label="שם"
                            fullWidth
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            sx={(theme) => ({
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 2,
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: theme.palette.primary.main,
                                        borderWidth: 2,
                                    },
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: theme.palette.primary.main,
                                },
                            })}
                        />

                        <TextField
                            label="טלפון"
                            fullWidth
                            required
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            sx={(theme) => ({
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 2,
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: theme.palette.primary.main,
                                        borderWidth: 2,
                                    },
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: theme.palette.primary.main,
                                },
                            })}
                        />

                        <Box
                            sx={{
                                display: "flex",
                                gap: 2,
                                justifyContent: "center",
                                mt: 3,
                            }}
                        >
                            <Button
                                variant="contained"
                                type="submit"
                                sx={(theme) => ({
                                    minWidth: 120,
                                    height: 44,
                                    background: theme.palette.gradients.primary,
                                    fontWeight: 600,
                                    '&:hover': {
                                        background: theme.palette.primary.dark,
                                    },
                                })}
                            >
                                הוסף
                            </Button>
                            <Button
                                variant="contained"
                                onClick={onCancel}
                                sx={(theme) => ({
                                    minWidth: 120,
                                    height: 44,
                                    background: theme.palette.error.light,
                                    color: theme.palette.error.contrastText,
                                    fontWeight: 600,
                                    '&:hover': {
                                        background: theme.palette.error.main,
                                    },
                                })}
                            >
                                ביטול
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Fade>
    );
};

export default AddInviteeForm;
