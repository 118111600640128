// src/components/Statistics.js
import React, { useState } from "react";
import { Box, Button, Card, CardContent, Typography, Grid, Fade } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTheme } from '@mui/material/styles';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

// Add a consistent card style
const StatCard = ({ value, label, gradient = 'primary' }) => (
  <Card
    elevation={2}
    sx={(theme) => ({
      height: '100%',
      background: theme.palette.background.paper,
      borderRadius: '16px',
      position: 'relative',
      overflow: 'hidden',
      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: '16px',
        padding: '4px',
        background: theme.palette.gradients[gradient],
        WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'xor',
        maskComposite: 'exclude',
        pointerEvents: 'none',
        opacity: 0,
        transition: 'opacity 0.8s cubic-bezier(0.4, 0, 0.2, 1)',
        '@media (prefers-reduced-motion: no-preference)': {
          transition: 'opacity 0.8s cubic-bezier(0.4, 0, 0.2, 1)',
        },
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '4px',
        background: theme.palette.gradients[gradient],
      },
      '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: '0 8px 24px rgba(142, 111, 144, 0.12)',
        '&::before': {
          opacity: 1,
          transition: 'opacity 1s cubic-bezier(0.4, 0, 0.2, 1)',
        },
      },
    })}
  >
    <CardContent sx={{ p: 3, position: 'relative', zIndex: 2 }}>
      <Typography
        variant="h4"
        component="div"
        sx={(theme) => ({
          color: theme.palette[gradient].main,
          fontWeight: 700,
          textAlign: 'center',
          mb: 1,
        })}
      >
        {value}
      </Typography>
      <Typography
        variant="body1"
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          textAlign: 'center',
          minHeight: '2.5em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 500,
          lineHeight: 1.3,
        })}
      >
        {label}
      </Typography>
    </CardContent>
  </Card>
);

const Statistics = ({ invitees }) => {
  const [showChart, setShowChart] = useState(true);
  const theme = useTheme();

  // Calculate statistics
  const totalNo = invitees.filter(inv => inv.attendance_status === "no").length;
  const totalMaybe = invitees.filter(inv => inv.attendance_status === "maybe").length;
  const totalNoResponse = invitees.filter(inv => inv.attendance_status === "no_response_yet").length;
  const totalYes = invitees.filter(inv => inv.attendance_status === "yes").length;
  const totalGuests = invitees
    .filter(inv => inv.attendance_status === "yes")
    .reduce((sum, inv) => sum + (inv.number_of_guests || 0), 0);

  // Calculate rates
  const responseRate = Math.round((invitees.length - totalNoResponse) / invitees.length * 100);
  const acceptanceRate = Math.round(totalYes / (totalYes + totalNo + totalMaybe) * 100 || 0);

  // Create chart data only for non-zero values
  const labels = [];
  const data = [];
  const colors = [];
  const hoverColors = [];

  if (totalGuests > 0) {
    labels.push("מגיעים");
    data.push(totalGuests);
    colors.push('#8AA17D');
    hoverColors.push('#9FB594');
  }
  
  if (totalNo > 0) {
    labels.push("לא מגיעים");
    data.push(totalNo);
    colors.push('#E28C88');
    hoverColors.push('#FFAAA5');
  }
  
  if (totalMaybe > 0) {
    labels.push("עדיין לא בטוחים");
    data.push(totalMaybe);
    colors.push('#E6B89C');
    hoverColors.push('#FFD3B6');
  }
  
  if (totalNoResponse > 0) {
    labels.push("לא הגיבו עדיין");
    data.push(totalNoResponse);
    colors.push('#92D2BE');
    hoverColors.push('#B5EAD7');
  }

  const chartData = {
    labels,
    datasets: [{
      data,
      backgroundColor: colors,
      hoverBackgroundColor: hoverColors,
    }],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        rtl: true,
        labels: {
          font: {
            family: '"Heebo", "Assistant", "Roboto", sans-serif',
            size: 14,
            weight: 500,
          },
          color: theme.palette.text.primary,
          padding: 20,
        },
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        color: '#FFFFFF',
        font: {
          weight: 'bold',
          size: 14,
          family: '"Heebo", "Assistant", "Roboto", sans-serif',
        },
        formatter: (value, ctx) => {
          const total = ctx.dataset.data.reduce((a, b) => a + b, 0);
          const percentage = ((value / total) * 100).toFixed(1);
          return `${value}\n(${percentage}%)`;
        },
        textAlign: 'center',
      },
    },
  };

  return (
    <Fade in={true} timeout={800}>
      <Box sx={{ p: 2 }}>
        {/* Total Invitees Counter */}
        <Typography
          variant="h6"
          sx={(theme) => ({
            textAlign: 'center',
            mb: 3,
            color: theme.palette.text.secondary,
            fontWeight: 500,
            '& strong': {
              color: theme.palette.primary.main,
              fontWeight: 700
            }
          })}
        >
          סה"כ <strong>{invitees.length}</strong> אורחים הוזמנו
        </Typography>

        {/* Mobile Toggle Button */}
        <Box sx={{ display: { xs: 'block', md: 'none' }, mb: 3 }}>
          <Button
            variant="contained"
            onClick={() => setShowChart(!showChart)}
            sx={(theme) => ({
              background: theme.palette.gradients.primary,
              color: theme.palette.primary.contrastText,
              fontWeight: 600,
              '&:hover': {
                background: theme.palette.primary.dark,
              },
            })}
          >
            {showChart ? "הראה קלפים" : "הראה גרף עוגה"}
          </Button>
        </Box>

        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={6} sx={{ 
            display: { 
              xs: showChart ? 'block' : 'none', 
              md: 'block' 
            }
          }}>
            <Box
              sx={(theme) => ({
                height: 400,
                p: 3,
                background: theme.palette.background.gradient,
                borderRadius: 3,
                boxShadow: theme.shadows[2],
                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: '0 8px 24px rgba(142, 111, 144, 0.12)',
                },
              })}
            >
              <Doughnut data={chartData} options={chartOptions} />
            </Box>
          </Grid>

          {/* Statistics Cards */}
          <Grid item xs={12} md={6} sx={{ 
            display: { 
              xs: showChart ? 'none' : 'block', 
              md: 'block' 
            }
          }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <StatCard
                  value={totalGuests}
                  label="מספר האנשים שמגיעים בסה״כ"
                  gradient="success"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StatCard
                  value={totalNo}
                  label="לא מגיעים"
                  gradient="error"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StatCard
                  value={totalMaybe}
                  label="עדיין לא בטוחים"
                  gradient="warning"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StatCard
                  value={totalNoResponse}
                  label="לא הגיבו עדיין"
                  gradient="info"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StatCard
                  value={`${responseRate}%`}
                  label="אחוז המוזמנים שהגיבו"
                  gradient="primary"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StatCard
                  value={`${acceptanceRate}%`}
                  label="אחוז המגיעים מתוך מי שהגיב"
                  gradient="accent"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  );
};

export default Statistics;
