// src/components/LandingPage/NavbarMenu.js

import React from 'react';
import { List, ListItem, ListItemText, Box, Divider } from '@mui/material';

function NavbarMenu() {
    const menuItems = [
        { text: 'בית', id: 'hero' },
        { text: 'תכונות', id: 'features' },
        { text: 'מחירים', id: 'price' },
        { text: 'עלינו', id: 'about' },
        { text: 'הדגמה', id: 'demo-video' },
        { text: 'שאלות נפוצות', id: 'faq' },
        { text: 'צור קשר', id: 'contact' },
    ];

    return (
        <Box sx={{ direction: 'rtl' }}>
            <List sx={{ 
                pt: 2,
                '& .MuiListItem-root': {
                    transition: theme => theme.transitions.create(['background-color', 'transform']),
                }
            }}>
                {menuItems.map((item, index) => (
                    <React.Fragment key={item.text}>
                        <ListItem
                            button
                            onClick={() => {
                                const section = document.getElementById(item.id);
                                if (section) {
                                    section.scrollIntoView({ behavior: 'smooth' });
                                }
                            }}
                            sx={{
                                textAlign: 'right',
                                py: 1.5,
                                px: 3,
                                color: 'text.primary',
                                '&:hover': {
                                    backgroundColor: 'background.overlay',
                                    transform: 'translateX(-4px)',
                                    '& .MuiListItemText-primary': {
                                        color: 'primary.main',
                                        fontWeight: 600
                                    }
                                },
                            }}
                        >
                            <ListItemText 
                                primary={item.text}
                                sx={{
                                    '& .MuiListItemText-primary': {
                                        fontWeight: 500,
                                        textAlign: 'right',
                                        fontSize: '1rem',
                                        transition: theme => theme.transitions.create(['color', 'font-weight']),
                                    }
                                }}
                            />
                        </ListItem>
                        {index < menuItems.length - 1 && (
                            <Divider 
                                variant="middle" 
                                sx={{
                                    borderColor: 'divider',
                                    opacity: 0.6
                                }}
                            />
                        )}
                    </React.Fragment>
                ))}
            </List>
        </Box>
    );
}

export default NavbarMenu;
