// src/components/EventHeader.js
import React from "react";
import { Typography, Box } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import PlaceIcon from "@mui/icons-material/Place";

const EventHeader = ({ eventData }) => {
    // Parse the date string into a Date object
    const eventDate = new Date(eventData.datetime);

    // Arrays for days of the week and months in Hebrew
    const daysOfWeek = [
        "יום ראשון", "יום שני", "יום שלישי", "יום רביעי",
        "יום חמישי", "יום שישי", "יום שבת",
    ];
    const months = [
        "ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני",
        "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר",
    ];

    // Format the date components
    const dayOfWeek = daysOfWeek[eventDate.getDay()];
    const day = eventDate.getDate();
    const month = months[eventDate.getMonth()];
    const year = eventDate.getFullYear();
    const hours = eventDate.getHours().toString().padStart(2, '0');
    const minutes = eventDate.getMinutes().toString().padStart(2, '0');
    const formattedDate = `${dayOfWeek}, ${day} ב${month} ${year}, בשעה ${hours}:${minutes}`;

    return (
        <Box
            sx={(theme) => ({
                textAlign: "center",
                mt: 4,
                mb: 4,
                position: 'relative',
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: -16,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '60px',
                    height: '2px',
                    background: theme.palette.gradients.primary,
                    borderRadius: theme.shape.borderRadius,
                }
            })}
        >
            <Typography
                variant="h3"
                gutterBottom
                sx={(theme) => ({
                    color: theme.palette.primary.main,
                    fontFamily: "'Playfair Display', Heebo, Arial",
                    mb: 3,
                    fontWeight: 600,
                    position: 'relative',
                    display: 'inline-block',
                    padding: '8px 24px',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: theme.palette.background.paper,
                        opacity: 0.9,
                        borderRadius: theme.shape.borderRadius * 2,
                        zIndex: -1,
                        boxShadow: theme.shadows[1],
                    },
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: -2,
                        left: -2,
                        right: -2,
                        bottom: -2,
                        background: theme.palette.gradients.primary,
                        opacity: 0.1,
                        borderRadius: theme.shape.borderRadius * 2,
                        zIndex: -2,
                    }
                })}
            >
                {eventData.event_name}
            </Typography>

            <Box 
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 4,
                    flexWrap: 'wrap'
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1
                    }}
                >
                    <EventIcon 
                        sx={(theme) => ({
                            color: theme.palette.secondary.main,
                            fontSize: '1.5rem',
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.1)',
                                color: theme.palette.secondary.dark
                            }
                        })}
                    />
                    <Typography 
                        variant="subtitle1"
                        sx={(theme) => ({
                            color: theme.palette.text.primary,
                            fontWeight: 500,
                            fontFamily: theme.typography.fontFamily
                        })}
                    >
                        {formattedDate}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1
                    }}
                >
                    <PlaceIcon 
                        sx={(theme) => ({
                            color: theme.palette.secondary.main,
                            fontSize: '1.5rem',
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.1)',
                                color: theme.palette.secondary.dark
                            }
                        })}
                    />
                    <Typography 
                        variant="subtitle1"
                        sx={(theme) => ({
                            color: theme.palette.text.primary,
                            fontWeight: 500,
                            fontFamily: theme.typography.fontFamily
                        })}
                    >
                        {eventData.location}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default EventHeader;
