// src/components/LandingPage/FAQSection.js

import React from 'react';
import { Box, Typography, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { motion } from 'framer-motion';

function FAQSection() {
    const faqs = [
        {
            question: 'מה קורה אם שכחתי להוסיף מוזמן לרשימה?',
            answer: 'אין בעיה! תוכלו להוסיף מוזמנים חדשים בכל שלב דרך המערכת. אנחנו נדאג לכלול אותם בסבב שליחת ההודעות הבא, וניצור איתם קשר טלפוני במידה ולא יאשרו הגעה.',
        },
        {
            question: 'האם אפשר לערוך פרטי מוזמן לאחר העלאת הרשימה?',
            answer: 'בהחלט! תוכלו לערוך את כל פרטי המוזמנים בכל זמן דרך המערכת - כולל שם ומספר טלפון. כל השינויים מתעדכנים בזמן אמת במערכת.',
        },
        {
            question: 'מה קורה אם יש טעות במספר טלפון של מוזמן?',
            answer: 'המערכת מזהה באופן אוטומטי מספרי טלפון שגויים ומסמנת אותם בצבע אדום. אנחנו נקבל התראה על כך ונצור איתכם קשר על מנת שתוכלו לעדכן במערכת את המספר התקין. לאחר העדכון, נשלח הודעה מחדש.',
        },
        {
            question: 'איך עובדת האפשרות למתן מתנה?',
            answer: 'ביום האירוע נשלח למוזמנים הודעת SMS עם קישורים לאפליקציות תשלום כמו Bit ו-Paybox שתספקו לנו. אנחנו עובדים על פיתוח אפשרות למתן מתנה באמצעות כרטיס אשראי ישירות דרך המערכת - נעדכן בהמשך כשהאפשרות תהיה זמינה.',
        },
        {
            question: 'האם המוזמנים יכולים לשנות את תשובתם לאחר אישור הגעה?',
            answer: 'כן! כל מוזמן יכול לשנות את תשובתו במקרה של שינוי בתכניות על ידי שימוש באותו קישור שקיבל בהודעת ה-SMS. תוכלו לראות את כל השינויים בסטטוס ההגעה בלוח הבקרה שלכם.',
        },
        {
            question: 'כמה זמן לפני האירוע כדאי להתחיל עם המערכת?',
            answer: 'אנחנו ממליצים לסגור את הפרטים איתנו כחודש לפני האירוע. התהליך כולו אורך כ-7 ימי עסקים - 2-3 ימים לשליחת הודעות ה-SMS ו-3-4 ימים לשיחות הטלפון. במקרה של חגים באמצע התהליך, יש לקחת בחשבון זמן נוסף.',
        },
    ];

    return (
        <Box 
            id="faq" 
            component="section"
            sx={{ 
                mt: 0,
                mb: 0,
                textAlign: 'center',
                background: 'transparent',
                py: 6,
                px: 2
            }}
        >
            <Typography 
                variant="h4" 
                gutterBottom
                sx={{
                    color: 'primary.main',
                    mb: 4,
                    fontWeight: theme => theme.typography.h4.fontWeight
                }}
            >
                שאלות נפוצות
            </Typography>
            <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} md={8}>
                    {faqs.map((faq, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ 
                                duration: 0.5,
                                delay: index * 0.1 
                            }}
                        >
                            <Accordion 
                                sx={{
                                    mb: 2,
                                    background: theme => theme.palette.gradients.card,
                                    borderRadius: `${theme => theme.shape.borderRadius}px !important`,
                                    boxShadow: theme => theme.shadows[2],
                                    transition: theme => theme.transitions.create(
                                        ['box-shadow', 'margin'],
                                        { duration: theme.transitions.duration.shorter }
                                    ),
                                    '&:before': {
                                        display: 'none', // Removes the default divider
                                    },
                                    '&:hover': {
                                        boxShadow: theme => theme.shadows[4]
                                    },
                                    '&.Mui-expanded': {
                                        margin: theme => theme.spacing(0, 0, 2)
                                    }
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index + 1}-content`}
                                    id={`panel${index + 1}-header`}
                                    sx={{
                                        '& .MuiAccordionSummary-content': {
                                            color: 'primary.main',
                                            fontWeight: theme => theme.typography.h6.fontWeight
                                        },
                                        '& .MuiAccordionSummary-expandIconWrapper': {
                                            color: 'primary.main',
                                            transition: theme => 
                                                theme.transitions.create(['transform', 'color']),
                                            '&.Mui-expanded': {
                                                color: 'primary.dark'
                                            }
                                        }
                                    }}
                                >
                                    <Typography variant="h6">
                                        {faq.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography 
                                        variant="body1"
                                        sx={{
                                            color: 'text.primary',
                                            textAlign: 'right'
                                        }}
                                    >
                                        {faq.answer}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </motion.div>
                    ))}
                </Grid>
            </Grid>
        </Box>
    );
}

export default FAQSection;
