// src/components/LoadingScreen.js
import React from "react";
import { Box, Typography } from "@mui/material";

const LoadingScreen = () => {
    return (
        <Box
            sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                background: theme.palette.background.default,
                gap: 3
            })}
        >
            {/* Animated Loading Dots */}
            <Box
                sx={(theme) => ({
                    position: 'relative',
                    width: '120px',
                    height: '120px',
                    '&::before, &::after': {
                        content: '""',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        background: theme.palette.gradients.primary,
                        opacity: 0.6,
                        animation: 'pulse 2s ease-out infinite',
                    },
                    '&::after': {
                        animation: 'pulse 2s ease-out infinite 1s',
                    },
                    '@keyframes pulse': {
                        '0%': {
                            transform: 'scale(0)',
                            opacity: 0.8,
                        },
                        '100%': {
                            transform: 'scale(1)',
                            opacity: 0,
                        },
                    }
                })}
            >
                <Box
                    sx={(theme) => ({
                        position: 'absolute',
                        inset: 0,
                        borderRadius: '50%',
                        background: theme.palette.background.paper,
                        boxShadow: theme.shadows[4],
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '&::after': {
                            content: '""',
                            width: '40%',
                            height: '40%',
                            borderRadius: '50%',
                            background: theme.palette.gradients.primary,
                            animation: 'bounce 1.2s ease-in-out infinite',
                        },
                        '@keyframes bounce': {
                            '0%, 100%': {
                                transform: 'scale(0.8)',
                                opacity: 0.8,
                            },
                            '50%': {
                                transform: 'scale(1.2)',
                                opacity: 1,
                            },
                        }
                    })}
                />
            </Box>

            {/* Loading Text */}
            <Typography
                variant="h5"
                sx={(theme) => ({
                    color: theme.palette.primary.main,
                    fontWeight: 500,
                    position: 'relative',
                    '&::after': {
                        content: '"..."',
                        position: 'absolute',
                        animation: 'loadingDots 1.5s infinite',
                    },
                    '@keyframes loadingDots': {
                        '0%': { opacity: 0.2 },
                        '20%': { opacity: 1 },
                        '100%': { opacity: 0.2 },
                    }
                })}
            >
                טוען
            </Typography>
        </Box>
    );
};

export default LoadingScreen;
