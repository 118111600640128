import React from 'react';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';

function LoadingScreen() {
    // Variants for the main container fade in/out
    const containerVariants = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 }
    };

    // Variants for the loading dots
    const dotsVariants = {
        animate: {
            transition: {
                staggerChildren: 0.2,
            }
        }
    };

    // Animation for each dot
    const dotVariants = {
        initial: { y: 0, opacity: 0.2 },
        animate: {
            y: [-8, 0],
            opacity: [1, 0.2],
            transition: {
                duration: 0.8,
                repeat: Infinity,
                ease: "easeInOut"
            }
        }
    };

    // Circle gradient animation
    const circleVariants = {
        animate: {
            scale: [1, 1.1, 1],
            opacity: [0.3, 0.5, 0.3],
            transition: {
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
            }
        }
    };

    return (
        <motion.div
            variants={containerVariants}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <Box
                sx={(theme) => ({
                    backgroundColor: theme.palette.background.default,
                    minHeight: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    color: theme.palette.primary.main,
                    position: "relative",
                    overflow: "hidden"
                })}
            >
                {/* Background gradient circles */}
                <motion.div
                    variants={circleVariants}
                    animate="animate"
                    style={{
                        position: 'absolute',
                        width: '400px',
                        height: '400px',
                        borderRadius: '50%',
                        background: 'radial-gradient(circle, rgba(142,111,144,0.1) 0%, rgba(142,111,144,0) 70%)',
                    }}
                />

                {/* Logo */}
                <Box
                    component="img"
                    src="/assets/logo1.svg"
                    alt="Eventa Logo"
                    sx={{
                        width: '180px',
                        height: 'auto',
                        marginBottom: 4,
                        filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                    }}
                />

                {/* Loading text and dots */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography 
                        variant="h6"
                        sx={{
                            fontWeight: 500,
                            letterSpacing: 1,
                            color: 'primary.main'
                        }}
                    >
                        טוען
                    </Typography>
                    <motion.div
                        variants={dotsVariants}
                        initial="initial"
                        animate="animate"
                        style={{ display: 'flex', gap: '6px' }}
                    >
                        {[0, 1, 2].map((index) => (
                            <motion.span
                                key={index}
                                variants={dotVariants}
                                style={{
                                    width: '6px',
                                    height: '6px',
                                    borderRadius: '50%',
                                    backgroundColor: 'currentColor',
                                    display: 'inline-block'
                                }}
                            />
                        ))}
                    </motion.div>
                </Box>
            </Box>
        </motion.div>
    );
}

export default LoadingScreen;
