// src/components/InviteesTable.js
import React, { useState, useEffect } from "react";
import {
    Typography,
    Paper,
    TextField,
    Checkbox,
    FormControlLabel,
    Grid,
    Card,
    CardContent,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    TablePagination,
    IconButton,
    Modal,
    Button,
    Tooltip,
    Chip,
    Fade,
} from "@mui/material";
import {
    Phone as PhoneIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Error as ErrorIcon,
    Add as AddIcon,
    Search as SearchIcon,
    AccessTime,
} from "@mui/icons-material";
import InviteeForm from "./InviteeForm";
import AddInviteeForm from "./AddInviteeForm";
import axios from "axios";

const attendanceStatusLabels = {
    yes: "כן",
    no: "לא",
    maybe: "אולי",
    no_response_yet: "לא הגיבו",
};

const statusColors = {
    yes: "success",
    no: "error",
    maybe: "warning",
    no_response_yet: "info",
};

const formatDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('he-IL', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    }).format(date);
};

const InviteesTable = ({ invitees, setInvitees, eventToken }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchName, setSearchName] = useState("");
    const [searchPhone, setSearchPhone] = useState("");
    const [filterInfo, setFilterInfo] = useState(false);
    const [attendanceFilter, setAttendanceFilter] = useState("all");
    const [filteredInvitees, setFilteredInvitees] = useState(invitees);
    const [openModal, setOpenModal] = useState(false);
    const [currentInvitee, setCurrentInvitee] = useState(null);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [showInvalidOnly, setShowInvalidOnly] = useState(false);

    // Debounce function
    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    };

    // Filter logic
    useEffect(() => {
        let filtered = [...invitees]; // Create a copy to sort

        // Always sort by response time first (newest first)
        filtered.sort((a, b) => {
            if (!a.responded_at && !b.responded_at) return 0;
            if (!a.responded_at) return 1;
            if (!b.responded_at) return -1;
            return new Date(b.responded_at) - new Date(a.responded_at);
        });

        // Apply other filters
        if (showInvalidOnly) {
            filtered = filtered.filter((inv) => inv.is_number_valid === false);
        }

        if (searchName.trim() !== "") {
            filtered = filtered.filter((inv) =>
                inv.name.toLowerCase().includes(searchName.toLowerCase())
            );
        }

        if (searchPhone.trim() !== "") {
            filtered = filtered.filter((inv) => inv.phone.includes(searchPhone));
        }

        if (filterInfo) {
            filtered = filtered.filter(
                (inv) => inv.additional_info && inv.additional_info.trim() !== ""
            );
        }

        if (attendanceFilter !== "all") {
            filtered = filtered.filter((inv) => inv.attendance_status === attendanceFilter);
        }

        setFilteredInvitees(filtered);
        setPage(0);
    }, [searchName, searchPhone, filterInfo, attendanceFilter, invitees, showInvalidOnly]);

    // CRUD operations
    const handleAddInvitee = async (inviteeData) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/event/invitee?token=${eventToken}`,
                inviteeData
            );
            setInvitees([...invitees, response.data]);
            setOpenAddModal(false);
        } catch (error) {
            console.error("Error adding invitee:", error);
            alert(error.response?.data?.message || "אירעה שגיאה בעת הוספת האורח.");
        }
    };

    const handleDeleteInvitee = async (inviteeId) => {
        if (window.confirm("האם אתה בטוח שברצונך למחוק את האורח?")) {
            try {
                await axios.delete(
                    `${process.env.REACT_APP_API_BASE_URL}/event/invitee/${inviteeId}?token=${eventToken}`
                );
                setInvitees(invitees.filter((inv) => inv.id !== inviteeId));
            } catch (error) {
                console.error("Error deleting invitee:", error);
                alert(error.response?.data?.message || "אירעה שגיאה בעת מחיקת האורח.");
            }
        }
    };

    const handleSaveInvitee = async (updatedInvitee, inviteeId) => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_BASE_URL}/event/invitee/${inviteeId}`,
                updatedInvitee,
                {
                    params: { token: eventToken },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            setInvitees(invitees.map((inv) =>
                inv.id === inviteeId ? response.data : inv
            ));
            setOpenModal(false);
        } catch (error) {
            console.error("Error updating invitee:", error);
            alert(error.response?.data?.message || "אירעה שגיאה בעת עדכון האורח.");
        }
    };

    // Calculate invalidCount only where needed for rendering
    const invalidCount = invitees.filter(inv => inv.is_number_valid === false).length;

    // Simplified effect that directly calculates invalid count
    useEffect(() => {
        if (invitees.filter(inv => inv.is_number_valid === false).length === 0 && showInvalidOnly) {
            setShowInvalidOnly(false);
        }
    }, [invitees, showInvalidOnly]);

    return (
        <Fade in={true} timeout={800}>
            <Paper
                sx={(theme) => ({
                    width: "100%",
                    overflow: "hidden",
                    p: 3,
                    borderRadius: 2,
                    background: theme.palette.background.paper,
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        boxShadow: theme.shadows[8],
                        transform: 'translateY(-2px)',
                    },
                })}
            >
                {/* Header Section */}
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="h5" sx={{ fontWeight: 600 }}>
                            רשימת האורחים
                        </Typography>
                        <Typography 
                            variant="caption" 
                            sx={(theme) => ({
                                color: theme.palette.text.secondary,
                                backgroundColor: theme.palette.background.neutral,
                                padding: '4px 8px',
                                borderRadius: '12px',
                                fontSize: '0.75rem',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                            })}
                        >
                            <AccessTime fontSize="small" />
                            ממוין לפי תגובה אחרונה - החדש ביותר ראשון
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => setOpenAddModal(true)}
                        sx={(theme) => ({
                            background: theme.palette.gradients.primary,
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                                background: theme.palette.gradients.primary,
                                transform: 'translateY(-2px)',
                                boxShadow: theme.shadows[4],
                            },
                        })}
                    >
                        הוסף אורח
                    </Button>
                </Box>

                {/* Invalid Numbers Warning Banner */}
                {invalidCount > 0 && (
                    <Box 
                        sx={(theme) => ({ 
                            mb: 3,
                            p: 2,
                            background: theme.palette.gradients.error,
                            color: 'white',
                            borderRadius: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'translateY(-2px)',
                                boxShadow: theme.shadows[4],
                            },
                        })}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <ErrorIcon />
                            <Typography>
                                יש {invalidCount} מספרי טלפון לא תקינים שדורשים טיפול
                            </Typography>
                        </Box>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => setShowInvalidOnly(!showInvalidOnly)}
                            sx={(theme) => ({
                                backgroundColor: theme.palette.error.dark,
                                '&:hover': {
                                    backgroundColor: theme.palette.error.dark,
                                    transform: 'translateY(-2px)',
                                    boxShadow: theme.shadows[4],
                                },
                            })}
                        >
                            {showInvalidOnly ? 'הצג את כל האורחים' : 'הצג רק מספרים לא תקינים'}
                        </Button>
                    </Box>
                )}

                {/* Search and Filter Section */}
                <Grid container spacing={2} sx={{ mb: 3 }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            fullWidth
                            label="חיפוש לפי שם"
                            variant="outlined"
                            onChange={(e) => debounce(setSearchName(e.target.value), 300)}
                            InputProps={{
                                startAdornment: <SearchIcon color="action" />,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            fullWidth
                            label="חיפוש לפי טלפון"
                            variant="outlined"
                            onChange={(e) => debounce(setSearchPhone(e.target.value), 300)}
                            InputProps={{
                                startAdornment: <PhoneIcon color="action" />,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel>סטטוס השתתפות</InputLabel>
                            <Select
                                value={attendanceFilter}
                                label="סטטוס השתתפות"
                                onChange={(e) => setAttendanceFilter(e.target.value)}
                            >
                                <MenuItem value="all">הכל</MenuItem>
                                <MenuItem value="yes">מגיעים</MenuItem>
                                <MenuItem value="no">לא מגיעים</MenuItem>
                                <MenuItem value="maybe">אולי</MenuItem>
                                <MenuItem value="no_response_yet">לא הגיבו</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filterInfo}
                                    onChange={(e) => setFilterInfo(e.target.checked)}
                                />
                            }
                            label="הצג רק אורחים עם מידע נוסף"
                        />
                    </Grid>
                </Grid>

                {/* Updated Invitees Cards */}
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2}>
                        {filteredInvitees
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((invitee) => (
                                <Grid item xs={12} key={invitee.id}>
                                    <Card
                                        sx={(theme) => ({
                                            position: "relative",
                                            borderColor: invitee.is_number_valid === false ? 
                                                theme.palette.error.main : 
                                                theme.palette.divider,
                                            borderWidth: invitee.is_number_valid === false ? 2 : 1,
                                            borderStyle: "solid",
                                            background: invitee.is_number_valid === false ?
                                                'rgba(255, 118, 117, 0.05)' : // Light red background
                                                theme.palette.background.gradient,
                                            transition: 'all 0.3s ease-in-out',
                                            '&:hover': {
                                                transform: 'translateY(-4px)',
                                                boxShadow: theme.shadows[8],
                                                '&::before': {
                                                    opacity: 1,
                                                },
                                            },
                                            '&::before': {
                                                content: '""',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                borderRadius: '16px',
                                                padding: '2px',
                                                background: invitee.is_number_valid === false ?
                                                    theme.palette.gradients.error :
                                                    theme.palette.gradients.primary,
                                                WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                                                WebkitMaskComposite: 'xor',
                                                maskComposite: 'exclude',
                                                opacity: 0,
                                                transition: 'opacity 0.8s cubic-bezier(0.4, 0, 0.2, 1)',
                                            },
                                        })}
                                    >
                                        <CardContent>
                                            {invitee.is_number_valid === false && (
                                                <Box 
                                                    sx={{ 
                                                        mb: 2,
                                                        p: 1,
                                                        backgroundColor: 'error.main',
                                                        color: 'white',
                                                        borderRadius: 1,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 1
                                                    }}
                                                >
                                                    <ErrorIcon fontSize="small" />
                                                    <Typography variant="body2">
                                                        מספר טלפון לא תקין - נדרש עדכון
                                                    </Typography>
                                                </Box>
                                            )}
                                            <Grid container spacing={2}>
                                                {/* Main Info Section */}
                                                <Grid item xs={12} md={4}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                                        <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                                            {invitee.name}
                                                        </Typography>
                                                        {invitee.number_of_guests >= 2 && (
                                                            <Chip 
                                                                size="small"
                                                                label={`${invitee.number_of_guests - 1}+`}
                                                                color="primary"
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    </Box>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ 
                                                            display: "flex",
                                                            alignItems: "center",
                                                            gap: 1,
                                                            color: 'text.secondary'
                                                        }}
                                                    >
                                                        <PhoneIcon fontSize="small" />
                                                        <a href={`tel:${invitee.phone}`}>{invitee.phone}</a>
                                                    </Typography>
                                                </Grid>

                                                {/* Status Section with response time */}
                                                <Grid item xs={12} md={4}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                                        <Chip
                                                            label={attendanceStatusLabels[invitee.attendance_status]}
                                                            color={statusColors[invitee.attendance_status]}
                                                            sx={{ maxWidth: 'fit-content' }}
                                                        />
                                                        {invitee.responded_at && (
                                                            <Typography variant="caption" color="text.secondary">
                                                                הגיב/ה ב: {formatDate(invitee.responded_at)}
                                                            </Typography>
                                                        )}
                                                        {invitee.additional_info && (
                                                            <Typography 
                                                                variant="body2" 
                                                                sx={{ 
                                                                    mt: 1,
                                                                    p: 1,
                                                                    backgroundColor: 'rgba(0,0,0,0.03)',
                                                                    borderRadius: 1,
                                                                    maxWidth: '100%',
                                                                    wordBreak: 'break-word'
                                                                }}
                                                            >
                                                                {invitee.additional_info}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                </Grid>

                                                {/* Actions Section */}
                                                <Grid item xs={12} md={4}>
                                                    <Box sx={{ 
                                                        display: "flex", 
                                                        justifyContent: "flex-end",
                                                        alignItems: "flex-start",
                                                        gap: 1,
                                                        height: '100%'
                                                    }}>
                                                        <Tooltip title="ערוך">
                                                            <IconButton
                                                                onClick={() => {
                                                                    setCurrentInvitee(invitee);
                                                                    setOpenModal(true);
                                                                }}
                                                                sx={{
                                                                    '&:hover': {
                                                                        backgroundColor: 'primary.light',
                                                                        color: 'white',
                                                                    },
                                                                }}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="מחק">
                                                            <IconButton
                                                                onClick={() => handleDeleteInvitee(invitee.id)}
                                                                sx={{
                                                                    '&:hover': {
                                                                        backgroundColor: 'error.light',
                                                                        color: 'white',
                                                                    },
                                                                }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                    </Grid>
                </Box>

                {/* Pagination */}
                <TablePagination
                    component="div"
                    count={filteredInvitees.length}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={(e) => {
                        setRowsPerPage(parseInt(e.target.value, 10));
                        setPage(0);
                    }}
                    labelRowsPerPage="שורות בעמוד:"
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} מתוך ${count !== -1 ? count : `יותר מ-${to}`}`
                    }
                />

                {/* Modals */}
                <Modal open={openModal} onClose={() => setOpenModal(false)}>
                    <InviteeForm
                        invitee={currentInvitee}
                        onSave={handleSaveInvitee}
                        onCancel={() => setOpenModal(false)}
                    />
                </Modal>

                <Modal open={openAddModal} onClose={() => setOpenAddModal(false)}>
                    <AddInviteeForm
                        onSave={handleAddInvitee}
                        onCancel={() => setOpenAddModal(false)}
                    />
                </Modal>
            </Paper>
        </Fade>
    );
};

export default InviteesTable;
