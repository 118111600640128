import React, { useEffect, useState } from 'react';
import { Snackbar, Alert, Button, Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const InvalidNumbersNotification = ({ invitees }) => {
    const [open, setOpen] = useState(false);
    const invalidCount = invitees.filter(invitee => invitee.is_number_valid === false).length;

    useEffect(() => {
        if (invalidCount > 0) {
            setOpen(true);
        }
    }, [invalidCount]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    if (invalidCount === 0) return null;

    return (
        <Snackbar
            open={open}
            autoHideDuration={8000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{ 
                top: { xs: 8, sm: 24 },
                width: '100%',
                maxWidth: '800px',
                margin: '0 auto',
            }}
        >
            <Alert
                icon={<ErrorOutlineIcon sx={{ fontSize: '2rem' }} />}
                severity="error"
                variant="filled"
                sx={(theme) => ({
                    width: '100%',
                    padding: { xs: '12px 16px', sm: '16px 24px' },
                    borderRadius: '12px',
                    backgroundColor: theme.palette.error.main,
                    boxShadow: theme.shadows[4],
                    '& .MuiAlert-icon': {
                        opacity: 0.9,
                        marginRight: { xs: 1, sm: 2 },
                        padding: '4px',
                    },
                    '& .MuiAlert-message': {
                        padding: { xs: '4px 0', sm: '8px 0' },
                        flex: 1,
                    },
                    '& .MuiAlert-action': {
                        padding: { xs: '0 0 0 8px', sm: 0 },
                        marginLeft: 0,
                    },
                })}
                action={
                    <Button 
                        color="inherit" 
                        size="medium"
                        onClick={handleClose}
                        sx={{ 
                            fontWeight: 600,
                            px: { xs: 2, sm: 3 },
                            py: { xs: 0.5, sm: 0.75 },
                            borderRadius: '8px',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            fontSize: { xs: '0.875rem', sm: '1rem' },
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                            },
                        }}
                    >
                        הבנתי
                    </Button>
                }
            >
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'flex-start', sm: 'center' },
                    gap: { xs: 0.5, sm: 1 },
                    mr: { xs: 1, sm: 2 }
                }}>
                    <Typography 
                        component="span" 
                        sx={{ 
                            fontWeight: 500,
                            fontSize: { xs: '0.9rem', sm: '1rem' },
                            lineHeight: 1.5,
                        }}
                    >
                        {`נמצאו ${invalidCount} מספרי טלפון לא תקינים.`}
                    </Typography>
                    <Typography 
                        component="span"
                        sx={{ 
                            fontWeight: 400,
                            opacity: 0.9,
                            fontSize: { xs: '0.875rem', sm: '1rem' },
                        }}
                    >
                        אנא בדקו ועדכנו את המספרים.
                    </Typography>
                </Box>
            </Alert>
        </Snackbar>
    );
};

export default InvalidNumbersNotification;
