// src/components/EventClient/EventClient.js
import React, { useEffect, useState } from "react";
import { Container, Box, Divider, Paper, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Statistics from "./Statistics";
import InviteesTable from "./InviteesTable";
import "./App.css";
import axios from "axios";
import InvalidNumbersNotification from './InvalidNumbersNotification';
import RecentResponsesList from './RecentResponsesList';
import LoadingScreen from './LoadingScreen';

// Function to parse query parameters
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function EventClient() {
    const [eventData, setEventData] = useState(null);
    const [inviteesData, setInviteesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [recentResponses, setRecentResponses] = useState([]);

    const query = useQuery();
    const token = query.get("token");

    useEffect(() => {
        let mounted = true;

        const fetchEventData = async () => {
            try {
                document.title = 'Eventa - מערכת אישורים';
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/event`, {
                    params: { token: token },
                });

                if (!mounted) return;

                setEventData(response.data);
                setInviteesData(response.data.invitees);
                
                // Calculate the cutoff time (24 hours ago)
                const cutoffTime = new Date();
                cutoffTime.setHours(cutoffTime.getHours() - 24);

                // Process recent responses (only last 24h, max 10)
                const responses = response.data.invitees
                    .filter(inv => {
                        if (!inv.responded_at) return false;
                        const responseTime = new Date(inv.responded_at);
                        return responseTime > cutoffTime;
                    })
                    .sort((a, b) => new Date(b.responded_at) - new Date(a.responded_at))
                    .slice(0, 10);

                setRecentResponses(responses);
                
            } catch (err) {
                if (!mounted) return;
                setError("Failed to fetch event data.");
                console.error("Error fetching event data:", err);
            } finally {
                if (!mounted) return;
                setLoading(false);
            }
        };

        fetchEventData();

        return () => {
            mounted = false;
        };
    }, [token]);

    if (loading) {
        return <LoadingScreen />;
    }

    if (error) {
        return (
            <Box
                sx={(theme) => ({
                    backgroundColor: theme.palette.background.default,
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: theme.palette.error.main
                })}
            >
                <Typography variant="h6">{error}</Typography>
            </Box>
        );
    }

    if (!eventData) return null;

    return (
        <Box
            sx={(theme) => ({
                backgroundColor: theme.palette.background.default,
                minHeight: "100vh",
                paddingY: { xs: 3, md: 5 },
                color: theme.palette.text.primary,
                background: `linear-gradient(180deg, 
                    ${theme.palette.primary.light}15 0%, 
                    ${theme.palette.background.default} 25%,
                    ${theme.palette.background.default} 100%
                )`,
            })}
        >
            <InvalidNumbersNotification invitees={inviteesData} />
            
            <Container 
                maxWidth="lg"
                sx={(theme) => ({
                    '& > *:not(:last-child)': {
                        marginBottom: { xs: 3, md: 4 },
                    },
                })}
            >
                <Header
                    title={eventData.event_name}
                    date={eventData.datetime}
                    location={eventData.location}
                />

                {recentResponses.length > 0 && (
                    <Paper
                        elevation={2}
                        sx={(theme) => ({
                            background: theme.palette.background.paper,
                            borderRadius: 3,
                            padding: { xs: 2, md: 3 },
                            border: `1px solid ${theme.palette.primary.light}15`,
                            transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                            '&:hover': {
                                transform: 'translateY(-4px)',
                                boxShadow: '0 8px 24px rgba(142, 111, 144, 0.12)',
                            },
                        })}
                    >
                        <RecentResponsesList responses={recentResponses} />
                    </Paper>
                )}

                <Divider
                    sx={(theme) => ({
                        my: { xs: 3, md: 4 },
                        borderColor: theme.palette.divider,
                        opacity: 0.8,
                        '&::before, &::after': {
                            borderColor: theme.palette.divider,
                        },
                    })}
                />

                <Paper
                    elevation={2}
                    sx={(theme) => ({
                        background: theme.palette.background.paper,
                        borderRadius: 3,
                        padding: { xs: 2, md: 3 },
                        border: `1px solid ${theme.palette.primary.light}15`,
                        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                        '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: '0 8px 24px rgba(142, 111, 144, 0.12)',
                        },
                    })}
                >
                    <Statistics invitees={inviteesData} />
                </Paper>

                <Divider
                    sx={(theme) => ({
                        my: { xs: 3, md: 4 },
                        borderColor: theme.palette.divider,
                        opacity: 0.8,
                        '&::before, &::after': {
                            borderColor: theme.palette.divider,
                        },
                    })}
                />

                <Paper
                    elevation={2}
                    sx={(theme) => ({
                        background: theme.palette.background.paper,
                        borderRadius: 3,
                        padding: { xs: 2, md: 3 },
                        border: `1px solid ${theme.palette.primary.light}15`,
                        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                        '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: '0 8px 24px rgba(142, 111, 144, 0.12)',
                        },
                    })}
                >
                    <InviteesTable
                        invitees={inviteesData}
                        setInvitees={setInviteesData}
                        eventToken={token}
                    />
                </Paper>
            </Container>
        </Box>
    );
}

export default EventClient;
