// src/components/LandingPage/PriceSection.js

import React from 'react';
import { Box, Typography, Card, CardContent, Button } from '@mui/material';
import { motion } from 'framer-motion';
import PhoneIcon from '@mui/icons-material/Phone';

function PriceSection() {
    const price = {
        inviteeCost: 2, // NIS per invitee
        numberOfInvitees: 300,
    };

    const totalCost = price.inviteeCost * price.numberOfInvitees;

    // Animation Variants
    const cardVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { 
            opacity: 1, 
            y: 0, 
            transition: { 
                duration: 0.8, 
                ease: 'easeOut' 
            } 
        },
    };

    const buttonVariants = {
        initial: { scale: 1 },
        animate: {
            scale: [1, 1.05, 1],
            transition: {
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
            }
        },
        hover: {
            scale: 1.1,
            transition: {
                duration: 0.2
            }
        }
    };

    const handleContactClick = () => {
        const contactSection = document.getElementById('contact');
        if (contactSection) {
            contactSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Box 
            id="price" 
            component="section"
            sx={{ 
                mt: 0, 
                mb: 0, 
                textAlign: 'center',
                background: 'transparent',
                py: 6,
                px: 2
            }}
        >
            <Typography 
                variant="h4" 
                gutterBottom
                sx={{
                    color: 'primary.main',
                    mb: 4,
                    fontWeight: theme => theme.typography.h4.fontWeight
                }}
            >
                תמחור
            </Typography>
            <motion.div
                variants={cardVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
            >
                <Card 
                    elevation={0}
                    sx={{ 
                        maxWidth: 500, 
                        margin: '0 auto', 
                        padding: 4,
                        background: theme => theme.palette.gradients.card,
                        borderRadius: theme => theme.shape.borderRadius * 2,
                        transition: theme => theme.transitions.create(
                            ['transform', 'box-shadow'],
                            { duration: theme.transitions.duration.standard }
                        ),
                        '&:hover': {
                            transform: 'translateY(-8px)',
                            boxShadow: theme => theme.shadows[8]
                        }
                    }}
                >
                    <CardContent>
                        <Typography 
                            variant="h6" 
                            gutterBottom
                            sx={{
                                color: 'primary.main',
                                fontWeight: theme => theme.typography.h6.fontWeight,
                                mb: 3
                            }}
                        >
                            תשלום לפי אישור הגעה
                        </Typography>
                        <Typography 
                            variant="body1"
                            sx={{
                                color: 'text.primary',
                                mb: 3
                            }}
                        >
                            רק <strong>{price.inviteeCost} ש"ח</strong> עבור כל מוזמן שמקבל הודעת SMS לאישור הגעה.
                        </Typography>
                        <Box sx={{ mt: 4 }}>
                            <Typography 
                                variant="h6"
                                sx={{
                                    color: 'primary.main',
                                    fontWeight: theme => theme.typography.h6.fontWeight,
                                    mb: 2
                                }}
                            >
                                דוגמה:
                            </Typography>
                            <Typography 
                                variant="body1"
                                sx={{
                                    color: 'text.primary'
                                }}
                            >
                                אם יש לך <strong>{price.numberOfInvitees}</strong> מוזמנים, 
                                תשלם <strong>{totalCost} ש"ח</strong> בלבד!
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center' }}>
                            <motion.div
                                variants={buttonVariants}
                                initial="initial"
                                animate="animate"
                                whileHover="hover"
                            >
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={handleContactClick}
                                    endIcon={<PhoneIcon sx={{ marginRight: 1.25 }} />}
                                    sx={{
                                        background: theme => theme.palette.gradients.secondary,
                                        padding: '12px 30px',
                                        fontSize: '1.1rem',
                                        fontWeight: 'bold',
                                        color: 'text.primary',
                                        boxShadow: theme => theme.shadows[4],
                                        '&:hover': {
                                            background: theme => theme.palette.secondary.dark,
                                            boxShadow: theme => theme.shadows[8],
                                        }
                                    }}
                                >
                                    נשמע טוב? דברו איתנו!
                                </Button>
                            </motion.div>
                        </Box>
                    </CardContent>
                </Card>
            </motion.div>
        </Box>
    );
}

export default PriceSection;
