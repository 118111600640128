// src/components/LandingPage/ContactUsSection.js

import React, { useState } from 'react';
import { Box, Typography, Grid, TextField, Button, IconButton } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import emailjs from 'emailjs-com';

function ContactUsSection({ setSnackbar }) {
    const phoneNumber = '0544510215';
    const [formData, setFormData] = useState({ name: '', phone: '' });

    const handleSubmit = (e) => {
        e.preventDefault();
        const { name, phone } = formData;

        if (name.trim() === '' || phone.trim() === '') {
            setSnackbar({
                open: true,
                message: 'אנא מלא את כל השדות.',
                severity: 'error',
            });
            return;
        }

        const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
        const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
        const userID = process.env.REACT_APP_EMAILJS_USER_ID;

        const templateParams = {
            from_name: name,
            phone_number: phone,
        };

        emailjs.send(serviceID, templateID, templateParams, userID)
            .then(() => {
                setSnackbar({
                    open: true,
                    message: 'הפרטים שלך נשלחו בהצלחה!',
                    severity: 'success',
                });
                setFormData({ name: '', phone: '' });
            }, () => {
                setSnackbar({
                    open: true,
                    message: 'הייתה שגיאה בשליחת הפרטים. אנא נסה שוב מאוחר יותר.',
                    severity: 'error',
                });
            });
    };

    const handleWhatsAppClick = () => {
        const formattedPhone = phoneNumber.replace(/\D/g, '');
        window.open(`https://wa.me/972${formattedPhone.substring(1)}`, '_blank');
    };

    return (
        <Box 
            id="contact" 
            component="section"
            sx={{ 
                mt: 0,
                mb: 0,
                background: 'transparent',
                py: 6,
                px: 2
            }}
        >
            <Typography 
                variant="h4" 
                align="center" 
                gutterBottom
                sx={{
                    color: 'primary.main',
                    mb: 4,
                    fontWeight: theme => theme.typography.h4.fontWeight
                }}
            >
                צור קשר
            </Typography>
            <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center', 
                        justifyContent: 'center',
                        gap: 2,
                        mb: 4
                    }}>
                        <Typography 
                            variant="body1"
                            sx={{
                                color: 'text.primary',
                                textAlign: 'center',
                                mb: 1
                            }}
                        >
                            היי! אנחנו אלי ואליס, ונשמח לעזור לכם בכל שאלה או להתחיל בתהליך הרישום לשירות.
                        </Typography>
                        <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            gap: 1
                        }}>
                            <Typography 
                                variant="body1"
                                sx={{
                                    color: 'text.primary',
                                    textAlign: 'center'
                                }}
                            >
                                התקשרו או שלחו הודעת WhatsApp למספר: <strong>054-4510215</strong>
                            </Typography>
                            <IconButton
                                onClick={handleWhatsAppClick}
                                sx={{
                                    color: '#25D366',
                                    transition: theme => theme.transitions.create(['transform', 'box-shadow']),
                                    '&:hover': {
                                        transform: 'scale(1.1)',
                                        color: '#128C7E'
                                    }
                                }}
                                aria-label="התקשר בוואטסאפ"
                            >
                                <WhatsAppIcon fontSize="large" />
                            </IconButton>
                        </Box>
                        <Typography 
                            variant="body1"
                            sx={{
                                color: 'text.primary',
                                textAlign: 'center',
                                fontSize: '0.9rem',
                                mt: 1
                            }}
                        >
                            בין אם אתם מעוניינים להשתמש בשירות שלנו או סתם רוצים לשמוע עוד פרטים!
                        </Typography>
                    </Box>
                    <Typography 
                        variant="body1"
                        sx={{
                            color: 'text.primary',
                            textAlign: 'center',
                            mb: 4
                        }}
                    >
                        או השאירו את פרטיכם ואנחנו נחזור אליכם בהקדם!
                    </Typography>
                    <Box 
                        component="form" 
                        noValidate 
                        autoComplete="off" 
                        onSubmit={handleSubmit}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            maxWidth: '400px',
                            margin: '0 auto'
                        }}
                    >
                        <TextField
                            label="שם"
                            variant="outlined"
                            fullWidth
                            required
                            value={formData.name}
                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                            aria-label="שם"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    background: 'rgba(255, 255, 255, 0.8)',
                                    backdropFilter: 'blur(8px)',
                                }
                            }}
                        />
                        <TextField
                            label="מספר טלפון"
                            variant="outlined"
                            fullWidth
                            required
                            type="tel"
                            value={formData.phone}
                            onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                            aria-label="מספר טלפון"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    background: 'rgba(255, 255, 255, 0.8)',
                                    backdropFilter: 'blur(8px)',
                                }
                            }}
                        />
                        <Button 
                            type="submit" 
                            variant="contained" 
                            fullWidth
                            sx={{
                                mt: 2,
                                background: theme => theme.palette.gradients.primary,
                                '&:hover': {
                                    background: theme => theme.palette.primary.dark,
                                }
                            }}
                        >
                            שלח טופס
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ContactUsSection;
