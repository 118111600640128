// src/DigitalInvitation.js
import React from "react";
import { Box, Typography, Fade } from "@mui/material";

function DigitalInvitation({ imageUrl }) {
    return (
        <Fade in={true} timeout={1000}>
            <Box
                sx={(theme) => ({
                    textAlign: "center",
                    mt: 4,
                    mb: 6,
                    position: 'relative',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: -2,
                        left: -2,
                        right: -2,
                        bottom: -2,
                        borderRadius: theme.shape.borderRadius * 2,
                        background: theme.palette.gradients.primary,
                        opacity: 0,
                        transition: 'opacity 0.3s ease-in-out',
                        zIndex: 0,
                    },
                    '&:hover::before': {
                        opacity: 0.15,
                    },
                })}
            >
                {imageUrl ? (
                    <Box
                        component="img"
                        src={imageUrl}
                        alt="הזמנה דיגיטלית"
                        sx={(theme) => ({
                            maxWidth: "100%",
                            height: "auto",
                            borderRadius: theme.shape.borderRadius * 2,
                            position: 'relative',
                            zIndex: 1,
                            transition: 'all 0.3s ease-in-out',
                            boxShadow: theme.shadows[4],
                            '&:hover': {
                                transform: 'scale(1.02)',
                                boxShadow: theme.shadows[8],
                            },
                        })}
                    />
                ) : (
                    <Typography 
                        variant="body1"
                        sx={(theme) => ({
                            p: 3,
                            borderRadius: theme.shape.borderRadius,
                            bgcolor: 'background.paper',
                            color: 'text.secondary',
                            boxShadow: theme.shadows[2],
                        })}
                    >
                        אין הזמנה דיגיטלית להצגה
                    </Typography>
                )}
            </Box>
        </Fade>
    );
}

export default DigitalInvitation;
