// src/App.js
import React, { useState, useEffect } from "react";
import { Container, Box, ThemeProvider } from "@mui/material";
import theme from "../../theme"; // Import our global theme
import DigitalInvitation from "./DigitalInvitation";
import EventHeader from "./EventHeader";
import RSVPForm from "./RSVPForm";
import ThankYouMessage from "./ThankYouMessage";
import LoadingScreen from "./LoadingScreen";
import ErrorScreen from "./ErrorScreen";
import "./App.css";

function App() {
    const [attendance, setAttendance] = useState("");
    const [showAdditionalQuestions, setShowAdditionalQuestions] = useState(false);
    const [numGuests, setNumGuests] = useState("");
    const [additionalInfo, setAdditionalInfo] = useState("");
    const [additionalInfoPlaceholder, setAdditionalInfoPlaceholder] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [errors, setErrors] = useState({});
    const [inviteeData, setInviteeData] = useState(null);
    const [eventData, setEventData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    useEffect(() => {
        document.body.dir = "rtl";
        document.title = 'Eventa - אישור הגעה';

        if (token) {
            setLoading(true);
            fetch(`${process.env.REACT_APP_API_BASE_URL}/invitee?token=${token}`)
                .then((response) => {
                    if (!response.ok) {
                        return response.json().then((errorData) => {
                            throw new Error(errorData.message || "Error fetching invitee data");
                        });
                    }
                    return response.json();
                })
                .then((data) => {
                    setInviteeData(data);
                    setEventData(data.event);
                    setAttendance(data.attendance_status || "");
                    setNumGuests(data.number_of_guests ? data.number_of_guests.toString() : "");
                    setAdditionalInfo(data.additional_info || "");
                    setShowAdditionalQuestions(data.attendance_status === "yes");

                    // Set placeholder based on attendance status if additionalInfo is empty
                    if (!data.additional_info) {
                        let defaultPlaceholder = "";
                        switch (data.attendance_status) {
                            case "yes":
                                defaultPlaceholder = "";
                                break;
                            case "no":
                                defaultPlaceholder = "מצטער, לא אוכל להגיע.";
                                break;
                            case "maybe":
                                defaultPlaceholder = "אעדכן בהקדם האפשרי.";
                                break;
                            default:
                                defaultPlaceholder = "";
                        }
                        setAdditionalInfoPlaceholder(defaultPlaceholder);
                    }

                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching invitee data:", error);
                    setError("התרחשה שגיאה בטעינת הנתונים. אנא פנו למארגני האירוע.");
                    setLoading(false);
                });
        } else {
            setError("קישור לא תקין. אנא השתמשו בקישור שנשלח אליכם בהזמנה.");
            setLoading(false);
        }
    }, [token]);

    const validate = () => {
        const newErrors = {};

        if (!attendance) {
            newErrors.attendance = "אנא בחרו אפשרות";
        }

        if (attendance === "yes") {
            if (!numGuests) {
                newErrors.numGuests = "אנא הזינו מספר אורחים";
            } else if (parseInt(numGuests, 10) <= 0) {
                newErrors.numGuests = "מספר האורחים חייב להיות מספר חיובי";
            }
        }

        return newErrors;
    };

    const handleAttendanceChange = (event) => {
        const selectedAttendance = event.target.value;
        setAttendance(selectedAttendance);
        setShowAdditionalQuestions(selectedAttendance === "yes");
        setErrors((prevErrors) => ({ ...prevErrors, attendance: undefined }));

        if (!additionalInfo) {
            let defaultPlaceholder = "";
            switch (selectedAttendance) {
                case "yes":
                    defaultPlaceholder = "";
                    break;
                case "no":
                    defaultPlaceholder = "מצטער, לא אוכל להגיע.";
                    break;
                case "maybe":
                    defaultPlaceholder = "אעדכן בהקדם האפשרי.";
                    break;
                default:
                    defaultPlaceholder = "";
            }
            setAdditionalInfoPlaceholder(defaultPlaceholder);
        }
    };

    const handleNumGuestsChange = (e) => {
        setNumGuests(e.target.value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            numGuests: undefined,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const payload = {
            attendance_status: attendance,
            number_of_guests: attendance === "yes" ? parseInt(numGuests, 10) : 0,
            additional_info: additionalInfo,
        };

        fetch(
            `${process.env.REACT_APP_API_BASE_URL}/invitee/${inviteeData.unique_token}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            }
        )
            .then((response) => {
                if (response.ok) {
                    setFormSubmitted(true);
                } else {
                    return response.json().then((errorData) => {
                        throw new Error(errorData.message || "Error updating invitee data");
                    });
                }
            })
            .catch((error) => {
                console.error("Error updating invitee data:", error);
                setError("התרחשה שגיאה בשליחת הטופס. אנא נסו שוב.");
            });
    };

    if (loading) {
        return (
            <ThemeProvider theme={theme}>
                <LoadingScreen />
            </ThemeProvider>
        );
    }

    if (error) {
        return (
            <ThemeProvider theme={theme}>
                <ErrorScreen errorMessage={error} />
            </ThemeProvider>
        );
    }

    if (formSubmitted) {
        return (
            <ThemeProvider theme={theme}>
                <ThankYouMessage />
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={(theme) => ({
                    background: theme.palette.background.default,
                    minHeight: "100vh",
                    paddingBottom: "50px",
                    position: 'relative',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: "url(/assets/background-pattern.png)",
                        backgroundSize: "cover",
                        opacity: 0.5,
                        zIndex: 0,
                    }
                })}
            >
                <Container 
                    maxWidth="sm" 
                    sx={{ 
                        position: 'relative',
                        zIndex: 1 
                    }}
                >
                    <EventHeader eventData={eventData} />
                    <DigitalInvitation imageUrl={eventData.digital_invite_url} />
                    <Box
                        elevation={10}
                        component="div"
                        sx={{
                            mt: 2.5,
                            borderRadius: 2.5,
                            bgcolor: 'background.paper',
                            backdropFilter: "blur(10px)",
                            p: 2.5,
                        }}
                    >
                        <RSVPForm
                            attendance={attendance}
                            handleAttendanceChange={handleAttendanceChange}
                            showAdditionalQuestions={showAdditionalQuestions}
                            numGuests={numGuests}
                            handleNumGuestsChange={handleNumGuestsChange}
                            errors={errors}
                            additionalInfo={additionalInfo}
                            setAdditionalInfo={setAdditionalInfo}
                            additionalInfoPlaceholder={additionalInfoPlaceholder}
                            handleSubmit={handleSubmit}
                        />
                    </Box>
                </Container>
            </Box>
        </ThemeProvider>
    );
}

export default App;
