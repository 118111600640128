// src/theme.js
import { createTheme } from '@mui/material/styles';

// Define the color palette with wedding-themed colors
const colors = {
  primary: {
    main: '#8E6F90',      // Warm plum - sophisticated and elegant
    light: '#A788A9',     // Light plum
    dark: '#755A77',      // Deep plum
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#D4B08C',      // Warm beige - complements the plum
    light: '#E2C4A6',     // Light beige
    dark: '#BF9874',      // Deep beige
    contrastText: '#2D2418',
  },
  accent: {
    main: '#B8A3CC',      // Soft lavender - as accent
    light: '#CDB8DE',     // Light lavender
    dark: '#9B89AB',      // Deep lavender
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#8AA17D',
    light: '#9FB594',
    dark: '#738B67',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#E28C88',
    light: '#FFAAA5',
    dark: '#D27874',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#E6B89C',
    light: '#FFD3B6',
    dark: '#D4A589',
    contrastText: '#2D2418',
  },
  info: {
    main: '#92D2BE',
    light: '#B5EAD7',
    dark: '#7EC1AC',
    contrastText: '#2D2418',
  },
  background: {
    default: '#FAF6F1',   // Warm white
    paper: '#FFFFFF',
    gradient: 'linear-gradient(145deg, #FFFFFF 0%, #FAF6F1 100%)',
    overlay: 'rgba(142, 111, 144, 0.08)', // Updated to match new primary
  },
  text: {
    primary: '#2D2418',    // Deep warm brown
    secondary: '#5C4A35',  // Medium warm brown
    light: '#8C7355',      // Light warm brown
    disabled: '#D4C5B5',
  },
  gradients: {
    primary: 'linear-gradient(145deg, #8E6F90 0%, #A788A9 100%)',
    secondary: 'linear-gradient(145deg, #D4B08C 0%, #E2C4A6 100%)',
    accent: 'linear-gradient(145deg, #B8A3CC 0%, #CDB8DE 100%)',
    success: 'linear-gradient(145deg, #8AA17D 0%, #9FB594 100%)',
    error: 'linear-gradient(145deg, #FFAAA5 0%, #FFCBC7 100%)',
    warning: 'linear-gradient(145deg, #FFD3B6 0%, #FFE5D2 100%)',
    info: 'linear-gradient(145deg, #B5EAD7 0%, #D0F2E7 100%)',
    card: 'linear-gradient(145deg, #FFFFFF 0%, #FAF6F1 100%)',
  },
  divider: 'rgba(191, 162, 219, 0.12)', // Subtle divider using primary color
};

// Define typography settings
const typography = {
  fontFamily: '"Heebo", "Assistant", "Roboto", sans-serif',
  h1: {
    fontSize: '3rem',
    fontWeight: 700,
    color: colors.primary.main,
    lineHeight: 1.2,
  },
  h2: {
    fontSize: '2.5rem',
    fontWeight: 600,
    color: colors.primary.main,
  },
  h3: {
    fontSize: '2rem',
    fontWeight: 500,
    color: colors.primary.main,
  },
  h4: {
    fontSize: '1.75rem',
    fontWeight: 500,
    color: colors.primary.main,
  },
  h5: {
    fontSize: '1.5rem',
    fontWeight: 400,
    color: colors.primary.dark,
  },
  h6: {
    fontSize: '1.25rem',
    fontWeight: 400,
    color: colors.primary.dark,
  },
  subtitle1: {
    fontSize: '1rem',
    fontWeight: 400,
    color: colors.text.secondary,
  },
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: colors.text.secondary,
  },
  body1: {
    fontSize: '1rem',
    color: colors.text.primary,
    lineHeight: 1.6,
  },
  body2: {
    fontSize: '0.875rem',
    color: colors.text.secondary,
    lineHeight: 1.6,
  },
  button: {
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '0.975rem',
  },
  caption: {
    fontSize: '0.75rem',
    color: colors.text.secondary,
  },
  overline: {
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    color: colors.text.secondary,
  },
};

// Define component overrides for consistent styling
const components = {
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        backgroundColor: colors.background.default,
      },
      body: {
        backgroundColor: colors.background.default,
      },
      '#root': {
        backgroundColor: colors.background.default,
        minHeight: '100vh',
      }
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
        padding: '8px 24px',
        textTransform: 'none',
        fontWeight: 500,
        boxShadow: 'none',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          transform: 'translateY(-2px)',
        },
        '&:active': {
          boxShadow: 'none',
          transform: 'translateY(0)',
        },
        '&:disabled': {
          backgroundColor: colors.text.disabled,
          color: colors.text.light,
        },
      },
      containedPrimary: {
        background: colors.gradients.primary,
        color: colors.primary.contrastText,
        '&:hover': {
          background: colors.primary.dark,
        },
      },
      containedSecondary: {
        background: colors.gradients.secondary,
        color: colors.secondary.contrastText,
        '&:hover': {
          background: colors.secondary.dark,
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '16px',
        background: colors.gradients.card,
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
        transition: 'all 0.3s ease-in-out',
        overflow: 'hidden',
        padding: 0,
        '&:hover': {
          boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)',
          transform: 'translateY(-4px)',
        },
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        background: colors.gradients.primary,
        boxShadow: 'none',
        backdropFilter: 'blur(10px)',
        '& .MuiToolbar-root': {
          minHeight: '64px',
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          borderRadius: '8px',
          '& fieldset': {
            borderColor: colors.text.light,
          },
          '&:hover fieldset': {
            borderColor: colors.primary.light,
          },
          '&.Mui-focused fieldset': {
            borderColor: colors.primary.main,
          },
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        color: colors.text.primary,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: colors.primary.main,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${colors.divider}`,
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: colors.primary.dark,
        color: colors.primary.contrastText,
        fontSize: '0.875rem',
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: '12px',
        padding: '16px',
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: colors.primary.main,
        transition: 'background-color 0.2s ease-in-out',
        '&:hover': {
          backgroundColor: `${colors.primary.main}10`,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: '6px',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-1px)',
        },
      },
    },
  },
};

// Define custom shadows for depth effects
const shadows = [
  'none',
  '0px 1px 3px rgba(0, 0, 0, 0.12)',
  '0px 1px 5px rgba(0, 0, 0, 0.12)',
  '0px 1px 8px rgba(0, 0, 0, 0.12)',
  '0px 2px 4px rgba(0, 0, 0, 0.12)',
  '0px 2px 6px rgba(0, 0, 0, 0.12)',
  '0px 3px 5px rgba(0, 0, 0, 0.12)',
  '0px 3px 6px rgba(0, 0, 0, 0.12)',
  '0px 4px 8px rgba(0, 0, 0, 0.12)',
  '0px 5px 10px rgba(0, 0, 0, 0.12)',
  '0px 6px 12px rgba(0, 0, 0, 0.12)',
  '0px 7px 14px rgba(0, 0, 0, 0.12)',
  '0px 8px 16px rgba(0, 0, 0, 0.12)',
  '0px 9px 18px rgba(0, 0, 0, 0.12)',
  '0px 10px 20px rgba(0, 0, 0, 0.12)',
  '0px 12px 22px rgba(0, 0, 0, 0.12)',
  '0px 14px 24px rgba(0, 0, 0, 0.12)',
  '0px 16px 26px rgba(0, 0, 0, 0.12)',
  '0px 18px 28px rgba(0, 0, 0, 0.12)',
  '0px 20px 30px rgba(0, 0, 0, 0.12)',
  '0px 22px 32px rgba(0, 0, 0, 0.12)',
  '0px 24px 34px rgba(0, 0, 0, 0.12)',
  '0px 26px 36px rgba(0, 0, 0, 0.12)',
  '0px 28px 38px rgba(0, 0, 0, 0.12)',
  '0px 30px 40px rgba(0, 0, 0, 0.12)',
];

// Create the theme using Material-UI's createTheme function
const theme = createTheme({
  direction: 'rtl', // Enable RTL for Hebrew
  palette: {
    mode: 'light',
    ...colors,
  },
  typography,
  components,
  shadows,
  shape: {
    borderRadius: 8,
  },
  zIndex: {
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
});

export default theme;
