// src/components/LandingPage/Navbar.js

import React from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Drawer,
    Box,
    Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from '@mui/icons-material/Login';
import NavbarMenu from './NavbarMenu';

function Navbar({ toggleDrawer, drawerOpen, handleSignIn }) {
    return (
        <AppBar
            position="fixed"
            elevation={0}
            sx={{
                background: theme => theme.palette.background.gradient,
                backdropFilter: 'blur(8px)',
                borderBottom: theme => `1px solid ${theme.palette.divider}`,
            }}
            component="header"
        >
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* Left side controls */}
                <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                    {/* Hamburger Menu */}
                    <IconButton
                        edge="start"
                        color="primary"
                        aria-label="open navigation menu"
                        onClick={toggleDrawer(true)}
                        sx={{
                            transition: theme => theme.transitions.create(['background-color', 'transform']),
                            '&:hover': {
                                transform: 'rotate(90deg)',
                                backgroundColor: 'background.overlay'
                            }
                        }}
                    >
                        <MenuIcon />
                    </IconButton>

                    {/* Login Button */}
                    <Button
                        variant="contained"
                        color="secondary"
                        endIcon={<LoginIcon />}
                        onClick={handleSignIn}
                        sx={{
                            background: theme => theme.palette.gradients.secondary,
                            boxShadow: theme => theme.shadows[2],
                            '& .MuiButton-endIcon': {
                                marginRight: 1,
                                marginLeft: -0.5,
                            },
                            '&:hover': {
                                background: theme => theme.palette.secondary.dark,
                                transform: 'translateY(-2px)',
                                boxShadow: theme => theme.shadows[4],
                            }
                        }}
                    >
                        כניסה למערכת
                    </Button>
                </Box>

                {/* Left side logo (moved from right side) */}
                <Box
                    component="img"
                    src="/assets/logo1.svg"
                    alt="Eventa Logo"
                    sx={{
                        height: {
                            xs: '50px',
                            sm: '60px',
                            md: '70px',
                            lg: '75px'
                        },
                        width: 'auto',
                        maxWidth: {
                            xs: '380px',
                            sm: '460px',
                            md: '520px',
                            lg: '600px'
                        },
                        transform: "scale(2)",
                        objectFit: 'contain',
                        objectPosition: 'left center',
                        marginLeft: 2,
                        filter: 'drop-shadow(0 2px 4px rgba(0, 0, 0, 0.15))',
                        transition: 'transform 0.3s ease',
                        cursor: 'pointer',
                        '&:hover': {
                            transform: 'scale(2.1)',
                        }
                    }}
                    onClick={() => {
                        const section = document.getElementById('hero');
                        if (section) {
                            section.scrollIntoView({ behavior: 'smooth' });
                        }
                    }}
                />

                {/* Drawer */}
                <Drawer
                    anchor="left"
                    open={drawerOpen}
                    onClose={toggleDrawer(false)}
                    sx={{
                        '& .MuiDrawer-paper': {
                            width: 250,
                            background: theme => theme.palette.background.gradient,
                            borderRight: theme => `1px solid ${theme.palette.divider}`,
                            right: 0,
                            left: 'auto'
                        }
                    }}
                >
                    <Box
                        sx={{ 
                            width: 250,
                            direction: 'rtl',
                            p: 2
                        }}
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                    >
                        <NavbarMenu />
                    </Box>
                </Drawer>
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;
