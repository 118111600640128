import React from 'react';
import {
    Box,
    Typography,
    Card,
    Avatar,
    Grid,
    useMediaQuery,
} from '@mui/material';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import {
    CheckCircle,
    People,
    Dashboard,
    Email,
    Notifications,
    Phone,
    InsertDriveFile,
    Favorite,
    CreditCard,
} from '@mui/icons-material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Define your feature steps
const featureSteps = [
    {
        step: 0,
        title: 'יצירת קשר ראשוני',
        description: 'צרו איתנו קשר ואנחנו נדאג לרשום את פרטי האירוע ולהכין הכל בהתאם!',
        icon: <CheckCircle fontSize="large" />,
    },
    {
        step: 1,
        title: 'העברת רשימת מוזמנים',
        description: 'העבירו אלינו את רשימת המוזמנים בכל פורמט שנוח לכם ואנחנו נדאג להעלות את כולם למערכת. תוכלו תמיד לערוך ולהוסיף מוזמנים בעצמכם דרך המערכת',
        icon: <People fontSize="large" />,
    },
    {
        step: 2,
        title: 'גישה למערכת הניהול',
        description: 'תקבלו גישה מיידית למערכת שלנו, שם תוכלו לצפות בנתונים חיים ומתעדכנים בזמן אמת, לערוך פרטי מוזמנים ולעקוב אחר אישורי ההגעה באופן מיידי',
        icon: <Dashboard fontSize="large" />,
    },
    {
        step: 3,
        title: 'שליחת אישורי הגעה',
        description: 'נשלח הודעות SMS אישיות (בקרוב גם באמצעות WhatsApp) עם קישור בו יוכלו האורחים לצפות בהזמנה הדיגיטלית שלכם ולאשר הגעה',
        icon: <Email fontSize="large" />,
    },
    {
        step: 4,
        title: 'מעקב ותזכורות',
        description: 'אנחנו נשלח תזכורות למי שטרם אישר הגעה, ואתם תוכלו לעקוב אחר הסטטוס בכל רגע נתון',
        icon: <Notifications fontSize="large" />,
    },
    {
        step: 5,
        title: 'טיפול אישי במוזמנים',
        description: 'אנחנו ניצור קשר טלפוני עם מי שטרם השיב להזמנה, ונעדכן את הסטטוס במערכת באופן שוטף',
        icon: <Phone fontSize="large" />,
    },
    {
        step: 6,
        title: 'ייצוא נתונים להושבה',
        description: 'במידה ותרצו, נוכל לייצא עבורכם את נתוני המוזמנים בפורמט המתאים למערכת סידורי ההושבה שלכם',
        icon: <InsertDriveFile fontSize="large" />,
    },
    {
        step: 7,
        title: 'הנחיות הגעה ומתנות',
        description: 'ביום האירוע נשלח למוזמנים הודעת SMS עם קישור ל-Waze וקישורים לאפליקציות תשלום (Bit/Paybox) לנוחיותם. בקרוב - אפשרות למתן מתנה ישירות באמצעות כרטיס אשראי!',
        icon: <CreditCard fontSize="large" />,
    },
    {
        step: 8,
        title: 'הודעות תודה',
        description: 'לאחר האירוע, נשלח הודעות תודה מותאמות אישית לכל המוזמנים שהשתתפו בשמחתכם',
        icon: <Favorite fontSize="large" />,
    },
];

function KeyFeaturesSection() {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const carouselSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        pauseOnDotsHover: true,
        rtl: true,
        initialSlide: 0,
    };

    const renderFeatureCard = (feature, index) => {
        const cardContent = (
            <Card
                elevation={0}
                sx={{
                    p: 4,
                    height: '100%',
                    minHeight: '280px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    background: theme => theme.palette.gradients.card,
                    borderRadius: theme => theme.shape.borderRadius * 2,
                    transition: theme => theme.transitions.create(
                        ['transform', 'box-shadow'],
                        { duration: theme.transitions.duration.standard }
                    ),
                    '&:hover': {
                        transform: 'translateY(-8px)',
                        boxShadow: theme => theme.shadows[8],
                    },
                }}
            >
                <Avatar
                    sx={{
                        mb: 2,
                        width: 64,
                        height: 64,
                        background: theme => theme.palette.gradients.primary,
                        color: 'primary.contrastText',
                        transition: theme => theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shorter
                        }),
                        '&:hover': {
                            transform: 'scale(1.1)',
                        }
                    }}
                >
                    {feature.icon}
                </Avatar>
                <Typography 
                    variant="h6" 
                    gutterBottom
                    sx={{
                        color: 'primary.main',
                        fontWeight: theme => theme.typography.h6.fontWeight,
                        textAlign: 'center',
                        mb: 2,
                    }}
                >
                    {feature.title}
                </Typography>
                <Typography 
                    variant="body2" 
                    sx={{
                        color: 'text.secondary',
                        textAlign: 'center',
                        lineHeight: 1.6,
                        maxWidth: '90%',
                        margin: '0 auto',
                    }}
                >
                    {feature.description}
                </Typography>
            </Card>
        );

        if (isMobile) {
            return cardContent;
        }

        return (
            <motion.div
                key={feature.step}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ 
                    duration: 0.5,
                    delay: index * 0.1 
                }}
            >
                {cardContent}
            </motion.div>
        );
    };

    return (
        <Box 
            component="section" 
            id="features"
            sx={{
                py: 8,
                px: 2,
                background: 'transparent',
            }}
        >
            <Typography 
                variant="h4" 
                align="center" 
                gutterBottom
                sx={{
                    color: 'primary.main',
                    mb: 6,
                    fontWeight: theme => theme.typography.h4.fontWeight
                }}
            >
                איך זה עובד?
            </Typography>

            {isMobile ? (
                <Slider {...carouselSettings}>
                    {[...featureSteps].reverse().map((feature, index) => (
                        <Box key={feature.step} sx={{ p: 1 }}>
                            {renderFeatureCard(feature, index)}
                        </Box>
                    ))}
                </Slider>
            ) : (
                <Grid container spacing={3} sx={{ px: { xs: 2, md: 4 } }}>
                    {featureSteps.map((feature, index) => (
                        <Grid item xs={12} sm={6} md={4} key={feature.step}>
                            {renderFeatureCard(feature, index)}
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );
}

export default KeyFeaturesSection;
