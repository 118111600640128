import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Paper, 
    Typography, 
    IconButton, 
    Chip,
    Fade,
    Button
} from '@mui/material';
import { 
    KeyboardArrowLeft, 
    KeyboardArrowRight,
    AccessTime as TimeIcon
} from '@mui/icons-material';

const statusColors = {
    yes: "success",
    no: "error",
    maybe: "warning"
};

const statusLabels = {
    yes: "מגיע/ה",
    no: "לא מגיע/ה",
    maybe: "אולי מגיע/ה"
};

const RecentResponsesList = ({ responses, onMarkAsSeen }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [autoPlay, setAutoPlay] = useState(true);

    // Auto-scroll effect
    useEffect(() => {
        let interval;
        if (autoPlay && responses.length > 1) {
            interval = setInterval(() => {
                setCurrentIndex((prev) => 
                    prev === responses.length - 1 ? 0 : prev + 1
                );
            }, 5000); // Change slide every 5 seconds
        }
        return () => clearInterval(interval);
    }, [autoPlay, responses.length]);

    const handlePrevious = () => {
        setCurrentIndex((prev) => (prev === 0 ? responses.length - 1 : prev - 1));
        setAutoPlay(false);
    };

    const handleNext = () => {
        setCurrentIndex((prev) => (prev === responses.length - 1 ? 0 : prev + 1));
        setAutoPlay(false);
    };

    if (!responses.length) return null;

    const formatTimeAgo = (date) => {
        const now = new Date();
        const responseDate = new Date(date);
        const diffInMinutes = Math.floor((now - responseDate) / (1000 * 60));
        
        if (diffInMinutes < 60) {
            return `לפני ${diffInMinutes} דקות`;
        } else if (diffInMinutes < 1440) {
            const hours = Math.floor(diffInMinutes / 60);
            return `לפני ${hours} שעות`;
        } else {
            const days = Math.floor(diffInMinutes / 1440);
            return `לפני ${days} ימים`;
        }
    };

    return (
        <Fade in={true}>
            <Paper
                elevation={3}
                sx={(theme) => ({
                    p: 3,
                    mb: 3,
                    position: 'relative',
                    overflow: 'hidden',
                    background: theme.palette.background.gradient,
                    borderRadius: 2,
                    direction: 'rtl',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: theme.shadows[8],
                    },
                })}
            >
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 3,
                    justifyContent: 'space-between'
                }}>
                    <Typography variant="h5" sx={{ fontWeight: 600 }}>
                        עדכונים אחרונים
                    </Typography>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => setAutoPlay(!autoPlay)}
                        sx={(theme) => ({
                            background: autoPlay ? 
                                theme.palette.gradients.primary : 
                                'transparent',
                            color: autoPlay ? 
                                theme.palette.primary.contrastText : 
                                theme.palette.primary.main,
                            borderColor: theme.palette.primary.main,
                            '&:hover': {
                                background: theme.palette.gradients.primary,
                                color: theme.palette.primary.contrastText,
                            },
                        })}
                    >
                        {autoPlay ? 'עצור גלילה' : 'הפעל גלילה'}
                    </Button>
                </Box>

                <Box sx={{ 
                    position: 'relative', 
                    height: '280px',
                    overflow: 'hidden' 
                }}>
                    <Box
                        sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            transform: `translateX(${currentIndex * 100}%)`,
                            transition: 'transform 0.5s ease-in-out',
                        }}
                    >
                        {responses.map((response, index) => (
                            <Box
                                key={index}
                                sx={{
                                    minWidth: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    gap: 3,
                                    pt: 4,
                                    px: 4,
                                }}
                            >
                                <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                    {response.name}
                                </Typography>
                                
                                <Chip
                                    label={statusLabels[response.attendance_status]}
                                    color={statusColors[response.attendance_status]}
                                    sx={(theme) => ({ 
                                        px: 2,
                                        fontSize: '0.9rem',
                                        background: theme.palette.gradients[statusColors[response.attendance_status]],
                                        color: theme.palette[statusColors[response.attendance_status]].contrastText,
                                    })}
                                />

                                {response.additional_info && (
                                    <Typography 
                                        variant="body1"
                                        sx={(theme) => ({ 
                                            textAlign: 'center',
                                            fontStyle: 'italic',
                                            maxWidth: '80%',
                                            color: theme.palette.text.secondary,
                                            backgroundColor: theme.palette.background.overlay,
                                            p: 2,
                                            borderRadius: 1,
                                            borderRight: `4px solid ${theme.palette.primary.light}`,
                                        })}
                                    >
                                        "{response.additional_info}"
                                    </Typography>
                                )}
                            </Box>
                        ))}
                    </Box>

                    {/* Time indicator above dots */}
                    <Box sx={{ 
                        position: 'absolute', 
                        bottom: 40,
                        left: '50%', 
                        transform: 'translateX(-50%)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        color: 'text.light'
                    }}>
                        <TimeIcon fontSize="small" />
                        <Typography variant="caption">
                            {formatTimeAgo(responses[currentIndex].responded_at)}
                        </Typography>
                    </Box>

                    {/* Navigation Dots */}
                    <Box sx={{ 
                        position: 'absolute', 
                        bottom: 16, 
                        left: '50%', 
                        transform: 'translateX(-50%)',
                        display: 'flex',
                        gap: 1
                    }}>
                        {responses.map((_, index) => (
                            <Box
                                key={index}
                                onClick={() => {
                                    setCurrentIndex(index);
                                    setAutoPlay(false);
                                }}
                                sx={(theme) => ({
                                    width: 8,
                                    height: 8,
                                    borderRadius: '50%',
                                    backgroundColor: index === currentIndex ? 
                                        theme.palette.primary.main : 
                                        theme.palette.primary.light,
                                    opacity: index === currentIndex ? 1 : 0.5,
                                    cursor: 'pointer',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        opacity: 0.8,
                                        transform: 'scale(1.2)',
                                    },
                                })}
                            />
                        ))}
                    </Box>

                    {responses.length > 1 && (
                        <>
                            <IconButton
                                onClick={handlePrevious}
                                sx={(theme) => ({
                                    position: 'absolute',
                                    left: 8,
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    backgroundColor: theme.palette.background.paper,
                                    boxShadow: theme.shadows[2],
                                    transition: 'all 0.2s ease',
                                    '&:hover': {
                                        background: theme.palette.gradients.primary,
                                        color: theme.palette.primary.contrastText,
                                        transform: 'translateY(-50%) scale(1.1)',
                                    },
                                })}
                            >
                                <KeyboardArrowLeft />
                            </IconButton>
                            <IconButton
                                onClick={handleNext}
                                sx={(theme) => ({
                                    position: 'absolute',
                                    right: 8,
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    backgroundColor: theme.palette.background.paper,
                                    boxShadow: theme.shadows[2],
                                    transition: 'all 0.2s ease',
                                    '&:hover': {
                                        background: theme.palette.gradients.primary,
                                        color: theme.palette.primary.contrastText,
                                        transform: 'translateY(-50%) scale(1.1)',
                                    },
                                })}
                            >
                                <KeyboardArrowRight />
                            </IconButton>
                        </>
                    )}
                </Box>
            </Paper>
        </Fade>
    );
};

export default RecentResponsesList; 