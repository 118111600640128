// src/components/RSVPForm.js
import React from "react";
import {
    Grid,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormHelperText,
    TextField,
    InputAdornment,
    Button,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import InfoIcon from "@mui/icons-material/Info";
import SendIcon from '@mui/icons-material/Send';
import { TransitionGroup, CSSTransition } from "react-transition-group";

const RSVPForm = ({
    attendance,
    handleAttendanceChange,
    showAdditionalQuestions,
    numGuests,
    handleNumGuestsChange,
    errors,
    additionalInfo,
    setAdditionalInfo,
    additionalInfoPlaceholder,
    handleSubmit,
}) => {
    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                {/* Attendance Question */}
                <Grid item xs={12}>
                    <FormControl 
                        component="fieldset" 
                        error={!!errors.attendance}
                        sx={(theme) => ({
                            width: '100%',
                            padding: { xs: 2, sm: 4 },
                        })}
                    >
                        <FormLabel
                            component="legend"
                            sx={(theme) => ({
                                fontWeight: 600,
                                fontSize: { xs: '1.2rem', sm: '1.4rem' },
                                color: theme.palette.primary.main,
                                position: 'relative',
                                display: 'inline-block',
                                marginBottom: { xs: 1, sm: 1.5 },
                                paddingBottom: { xs: 0.75, sm: 1 },
                                '&.Mui-focused': {
                                    color: theme.palette.primary.main,
                                },
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                    width: '100%',
                                    height: '2px',
                                    background: `linear-gradient(to left, ${theme.palette.primary.main}, ${theme.palette.primary.main}15)`,
                                    borderRadius: '2px',
                                }
                            })}
                        >
                            * האם תגיעו לאירוע?
                        </FormLabel>
                        <RadioGroup 
                            value={attendance} 
                            onChange={handleAttendanceChange}
                            sx={{ 
                                mt: { xs: 1, sm: 1.5 },
                                '& .MuiFormControlLabel-root': {
                                    marginBottom: { xs: 0.25, sm: 0.25 },
                                    padding: { xs: '8px 12px', sm: '6px 16px' },
                                    borderRadius: '8px',
                                    transition: 'background-color 0.2s ease',
                                    '&:hover': {
                                        backgroundColor: 'background.overlay'
                                    }
                                }
                            }}
                        >
                            {['yes', 'no', 'maybe'].map((value) => (
                                <FormControlLabel
                                    key={value}
                                    value={value}
                                    control={
                                        <Radio 
                                            sx={(theme) => ({
                                                color: theme.palette.primary.light,
                                                padding: { xs: 0.5, sm: 0.75 },
                                                '&.Mui-checked': {
                                                    color: theme.palette.primary.main,
                                                },
                                                transition: 'all 0.2s ease-in-out',
                                            })}
                                        />
                                    }
                                    label={{
                                        yes: 'כן',
                                        no: 'לא',
                                        maybe: 'עדיין לא יודע/ת'
                                    }[value]}
                                    sx={(theme) => ({
                                        color: theme.palette.text.primary,
                                        margin: { xs: 0.25, sm: 0.25 },
                                        '& .MuiTypography-root': {
                                            fontSize: { xs: '1rem', sm: '1.1rem' },
                                            fontWeight: 500
                                        }
                                    })}
                                />
                            ))}
                        </RadioGroup>
                        {errors.attendance && (
                            <FormHelperText error sx={{ mt: { xs: 0.5, sm: 1 } }}>
                                {errors.attendance}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                {/* Additional Questions */}
                <TransitionGroup component={null}>
                    {showAdditionalQuestions && (
                        <CSSTransition key="additional-questions" timeout={500} classNames="fade">
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    label="כמה תהיו?"
                                    type="number"
                                    fullWidth
                                    value={numGuests}
                                    onChange={handleNumGuestsChange}
                                    error={!!errors.numGuests}
                                    helperText={errors.numGuests}
                                    required={attendance === "yes"}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon sx={{ color: theme => theme.palette.primary.main }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={(theme) => ({
                                        '& .MuiOutlinedInput-root': {
                                            background: theme.palette.background.paper,
                                            borderRadius: '100px',
                                            border: `1px solid ${theme.palette.primary.light}15`,
                                            transition: 'all 0.3s ease-in-out',
                                            '&:hover': {
                                                transform: 'translateY(-2px)',
                                                boxShadow: theme.shadows[2],
                                            },
                                            '& fieldset': {
                                                borderColor: 'transparent',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'transparent',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'transparent',
                                            }
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: theme.palette.primary.main,
                                            fontWeight: 500,
                                        }
                                    })}
                                />
                            </Grid>
                        </CSSTransition>
                    )}
                </TransitionGroup>

                {/* Additional Info */}
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        label="האם יש משהו נוסף שכדאי שנדע?"
                        multiline
                        rows={4}
                        fullWidth
                        value={additionalInfo}
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                        placeholder={additionalInfoPlaceholder}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <InfoIcon sx={{ color: theme => theme.palette.primary.main }} />
                                </InputAdornment>
                            ),
                        }}
                        sx={(theme) => ({
                            '& .MuiOutlinedInput-root': {
                                background: theme.palette.background.paper,
                                borderRadius: '24px',
                                border: `1px solid ${theme.palette.primary.light}15`,
                                transition: 'all 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'translateY(-2px)',
                                    boxShadow: theme.shadows[2],
                                },
                                '& fieldset': {
                                    borderColor: 'transparent',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'transparent',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'transparent',
                                }
                            },
                            '& .MuiInputLabel-root': {
                                color: theme.palette.primary.main,
                                fontWeight: 500,
                            }
                        })}
                    />
                </Grid>

                {/* Submit Button */}
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        endIcon={
                            <SendIcon sx={{ marginRight: 2 }} />
                        }
                        sx={(theme) => ({
                            marginTop: 2,
                            padding: '12px 24px',
                            borderRadius: '100px',
                            fontSize: '1.1rem',
                            fontWeight: 500,
                            background: theme.palette.gradients.primary,
                            transition: 'all 0.3s ease-in-out',
                            '& .MuiButton-endIcon': {
                                marginLeft: '16px',
                                marginRight: '-8px',
                            },
                            '&:hover': {
                                transform: 'translateY(-2px)',
                                boxShadow: theme.shadows[4],
                            },
                            '&:active': {
                                transform: 'translateY(0)',
                            }
                        })}
                    >
                        שלח
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default RSVPForm;
