// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './theme';
import LandingPage from './components/LandingPage/LandingPage';
import EventClient from './components/EventClient/EventClient';
import InviteeApp from './components/InviteeApp/InviteeApp';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <div dir="rtl">
                <CssBaseline />
                <Router>
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/invite" element={<InviteeApp />} />
                        <Route path="/event" element={<EventClient />} />
                        <Route path="*" element={<LandingPage />} />
                    </Routes>
                </Router>
            </div>
        </ThemeProvider>
    );
}

export default App;
