// src/components/ThankYouMessage.js
import React from "react";
import { Container, Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { motion } from "framer-motion";

const ThankYouMessage = () => {
    return (
        <Container maxWidth="sm" sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transform: 'translateY(-20%)',
            py: { xs: 4, sm: 6 }
        }}>
            <Box
                component={motion.div}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, ease: "easeOut" }}
                sx={(theme) => ({
                    width: '100%',
                    textAlign: "center",
                    padding: { xs: 3, md: 4 },
                    background: theme.palette.background.paper,
                    borderRadius: '24px',
                    boxShadow: theme.shadows[4],
                    position: 'relative',
                    overflow: 'hidden',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '4px',
                        background: theme.palette.gradients.primary,
                    }
                })}
            >
                <CheckCircleIcon
                    component={motion.svg}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ 
                        delay: 0.2,
                        duration: 0.5,
                        type: "spring",
                        stiffness: 200
                    }}
                    sx={(theme) => ({
                        fontSize: '4rem',
                        color: theme.palette.success.main,
                        marginBottom: 2,
                        filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1))'
                    })}
                />

                <Typography
                    variant="h4"
                    component={motion.h4}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.4, duration: 0.6 }}
                    sx={(theme) => ({
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                        marginBottom: 2,
                        fontFamily: "'Heebo', sans-serif",
                        position: 'relative',
                        display: 'inline-block',
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            bottom: -8,
                            left: '25%',
                            width: '50%',
                            height: '2px',
                            background: theme.palette.gradients.primary,
                            borderRadius: '2px',
                        }
                    })}
                >
                    תודה רבה על אישורך!
                </Typography>

                <Typography
                    variant="body1"
                    component={motion.p}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.6, duration: 0.6 }}
                    sx={(theme) => ({
                        color: theme.palette.text.secondary,
                        marginTop: 3,
                        fontSize: '1.1rem',
                        lineHeight: 1.6,
                        maxWidth: '80%',
                        margin: '0 auto'
                    })}
                >
                    תוכל להשתמש בקישור זה כדי לעדכן את תשובותיך במקרה של שינוי בתוכניות.
                </Typography>
            </Box>
        </Container>
    );
};

export default ThankYouMessage;
