// src/components/Header.js
import React, { useState, useEffect, useCallback } from "react";
import { Typography, Box, Paper, Grid } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import PlaceIcon from "@mui/icons-material/Place";
import { motion } from "framer-motion";

const Header = ({ title, date, location }) => {
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        isEventPassed: false
    });

    const calculateTimeLeft = useCallback(() => {
        const eventTime = new Date(date).getTime();
        const now = new Date().getTime();
        const difference = eventTime - now;

        if (difference > 0) {
            setTimeLeft({
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
                isEventPassed: false
            });
        } else {
            setTimeLeft(prev => ({
                ...prev,
                isEventPassed: true
            }));
        }
    }, [date]);

    useEffect(() => {
        calculateTimeLeft();
        const timer = setInterval(() => calculateTimeLeft(), 1000);
        return () => clearInterval(timer);
    }, [calculateTimeLeft]);

    return (
        <Box sx={(theme) => ({ 
            textAlign: "center", 
            direction: "rtl",
            padding: { xs: 2, md: 4 },
            borderRadius: '24px',
        })}>
            <Typography
                component={motion.div}
                variant="h3"
                sx={(theme) => ({
                    color: theme.palette.primary.main,
                    fontWeight: 600,
                    background: `linear-gradient(120deg, 
                        ${theme.palette.background.paper} 0%, 
                        ${theme.palette.background.default} 100%
                    )`,
                    display: 'inline-block',
                    padding: '12px 40px',
                    borderRadius: '100px',
                    marginBottom: 3,
                    position: 'relative',
                    overflow: 'hidden',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        inset: 0,
                        background: `linear-gradient(90deg,
                            transparent 0%,
                            ${theme.palette.primary.light}15 15%,
                            ${theme.palette.primary.light}25 30%,
                            ${theme.palette.primary.light}15 45%,
                            transparent 60%
                        )`,
                        transform: 'translateX(-100%)',
                        animation: 'shimmer 3s infinite',
                    },
                    '@keyframes shimmer': {
                        '0%': {
                            transform: 'translateX(-100%)',
                        },
                        '100%': {
                            transform: 'translateX(100%)',
                        }
                    }
                })}
                initial={{ opacity: 0, y: 20 }}
                animate={{ 
                    opacity: 1, 
                    y: 0,
                    transition: {
                        duration: 0.8,
                        ease: "easeOut"
                    }
                }}
                whileHover={{
                    scale: 1.05,
                    transition: { duration: 0.3 }
                }}
                whileTap={{
                    scale: 0.95,
                    transition: { duration: 0.2 }
                }}
            >
                <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                >
                    {title}
                </motion.span>
            </Typography>

            <Paper
                elevation={6}
                sx={(theme) => ({
                    padding: { xs: 2, sm: 3, md: 4 },
                    marginTop: 2,
                    borderRadius: 4,
                    background: `linear-gradient(135deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 100%)`,
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        transform: 'translateY(-6px)',
                        boxShadow: '0 12px 24px rgba(0,0,0,0.15)',
                    },
                })}
            >
                <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography
                            variant="subtitle1"
                            sx={(theme) => ({
                                display: "flex",
                                alignItems: "center",
                                color: theme.palette.text.primary,
                                "& .MuiSvgIcon-root": {
                                    marginLeft: 1,
                                    color: theme.palette.primary.main,
                                },
                            })}
                        >
                            <EventIcon />
                            {new Date(date).toLocaleString("he-IL", {
                                dateStyle: "full",
                                timeStyle: "short",
                            })}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="subtitle1"
                            sx={(theme) => ({
                                display: "flex",
                                alignItems: "center",
                                marginLeft: 2,
                                color: theme.palette.text.primary,
                                "& .MuiSvgIcon-root": {
                                    marginLeft: 1,
                                    color: theme.palette.primary.main,
                                },
                            })}
                        >
                            <PlaceIcon />
                            {location}
                        </Typography>
                    </Grid>
                </Grid>
                {/* Enhanced Countdown Timer */}
                {timeLeft.isEventPassed ? (
                    <Box
                        sx={{
                            marginTop: 4,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: { xs: 1.5, sm: 3 },
                            flexDirection: 'row', // Always horizontal
                        }}
                    >
                        <Box
                            sx={(theme) => ({
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                background: theme.palette.background.paper,
                                borderRadius: '16px',
                                padding: {
                                    xs: '12px',
                                    sm: '16px 24px'
                                },
                                minWidth: {
                                    xs: '65px',
                                    sm: '100px'
                                },
                                boxShadow: '0 4px 14px rgba(142, 111, 144, 0.1)',
                                transition: 'all 0.3s ease-in-out',
                            })}
                        >
                            <Typography
                                variant="h3"
                                sx={(theme) => ({
                                    color: theme.palette.primary.main,
                                    fontWeight: 700,
                                    fontSize: { 
                                        xs: '1.75rem',
                                        sm: '2.5rem', 
                                        md: '3rem' 
                                    },
                                    lineHeight: 1,
                                    mb: { xs: 0.5, sm: 1 },
                                })}
                            >
                                האירוע כבר התחיל!
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            marginTop: 4,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: { xs: 1.5, sm: 3 },
                            flexDirection: 'row', // Always horizontal
                        }}
                    >
                        {Object.entries(timeLeft)
                            .filter(([key]) => key !== 'isEventPassed')
                            .reverse()
                            .map(([unit, value]) => (
                                <Box
                                    key={unit}
                                    sx={(theme) => ({
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        background: theme.palette.background.paper,
                                        borderRadius: '16px',
                                        padding: {
                                            xs: '12px',
                                            sm: '16px 24px'
                                        },
                                        minWidth: {
                                            xs: '65px',
                                            sm: '100px'
                                        },
                                        boxShadow: '0 4px 14px rgba(142, 111, 144, 0.1)',
                                        transition: 'all 0.3s ease-in-out',
                                    })}
                                >
                                    <Typography
                                        variant="h3"
                                        sx={(theme) => ({
                                            color: theme.palette.primary.main,
                                            fontWeight: 700,
                                            fontSize: { 
                                                xs: '1.75rem',
                                                sm: '2.5rem', 
                                                md: '3rem' 
                                            },
                                            lineHeight: 1,
                                            mb: { xs: 0.5, sm: 1 },
                                        })}
                                    >
                                        {value.toString().padStart(2, "0")}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        sx={(theme) => ({
                                            color: theme.palette.text.secondary,
                                            fontWeight: 500,
                                            fontSize: { 
                                                xs: '0.75rem',
                                                sm: '0.875rem' 
                                            },
                                            letterSpacing: '0.5px',
                                        })}
                                    >
                                        {{
                                            days: "ימים",
                                            hours: "שעות",
                                            minutes: "דקות",
                                            seconds: "שניות",
                                        }[unit]}
                                    </Typography>
                                </Box>
                            ))}
                    </Box>
                )}
            </Paper>
        </Box>
    );
};

export default Header;
