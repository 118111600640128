// src/components/LandingPage/AboutUsSection.js

import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { motion } from 'framer-motion';

function AboutUsSection() {
    return (
        <Box id="about" sx={{ 
            mt: 8, 
            mb: 8,
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            background: 'transparent',
        }} component="section">
            <Typography 
                variant="h4" 
                align="center" 
                gutterBottom
                sx={{
                    color: 'primary.main',
                    fontWeight: theme => theme.typography.h4.fontWeight
                }}
            >
                עלינו
            </Typography>
            
            {/* Text Content */}
            <Box sx={{ 
                mb: 4,
                color: 'text.primary',
                '& .MuiTypography-root': {
                    lineHeight: theme => theme.typography.body1.lineHeight
                }
            }}>
                <Typography variant="body1" paragraph>
                    היי! אנחנו אלי ואליס, זוג טרי שהתחתן באוקטובר 2024. כשתכננו את החתונה שלנו, גם אנחנו חיפשנו פתרון לניהול אישורי הגעה שיהיה גם יעיל וגם משתלם.
                </Typography>
                <Typography variant="body1" paragraph>
                    להפתעתנו, גילינו שהאפשרויות המובילות בשוק או שהיו יקרות מאוד (1,200-1,400 ש״ח ל-300 מוזמנים!) או שלא סיפקו את מה שחיפשנו - מערכת בזמן אמת שתאפשר מעקב פשוט ונוח אחר אישורי ההגעה.
                </Typography>
                <Typography variant="body1" paragraph>
                    מכאן נולדה Eventa - שירות שמשלב מחיר הוגן עם פתרון מקיף ואיכותי. המטרה שלנו היא להוריד מכם את כל הדאגות הקשורות לניהול המוזמנים, תוך שמירה על עדכון שוטף ושקיפות מלאה. אנחנו מאמינים שהשילוב של מערכת חכמה יחד עם ליווי אישי וצמוד הוא המפתח לחוויה מושלמת - גם למארגנים וגם למוזמנים.
                </Typography>
            </Box>

            {/* Responsive Images */}
            <Grid container spacing={2} sx={{ 
                mb: 4,
                width: '100%',
                maxWidth: {
                    xs: '90%',
                    sm: '80%',
                    md: '70%',
                    lg: '60%'
                },
                mx: 'auto'
            }}>
                <Grid item xs={12} md={6}>
                    <Box
                        component={motion.img}
                        src="/assets/alice_eli_1.jpg"
                        alt="Wedding Scene 1"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}
                        sx={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: theme => theme.shape.borderRadius,
                            boxShadow: theme => theme.shadows[4],
                            transition: theme => theme.transitions.create(['transform', 'box-shadow']),
                            '&:hover': {
                                transform: 'scale(1.02)',
                                boxShadow: theme => theme.shadows[8]
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ 
                    display: { 
                        xs: 'none',
                        md: 'block'
                    }
                }}>
                    <Box
                        component={motion.img}
                        src="/assets/alice_eli_2.jpg"
                        alt="Wedding Scene 2"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        sx={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: theme => theme.shape.borderRadius,
                            boxShadow: theme => theme.shadows[4],
                            transition: theme => theme.transitions.create(['transform', 'box-shadow']),
                            '&:hover': {
                                transform: 'scale(1.02)',
                                boxShadow: theme => theme.shadows[8]
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default AboutUsSection;
