// src/components/LandingPage/HeroSection.js

import React from 'react';
import { Box, Typography } from '@mui/material';

function HeroSection() {
    return (
        <Box
            id="hero"
            sx={{
                height: { xs: '60vh', md: '70vh' },
                position: 'relative',
                maxHeight: '800px',
                overflow: 'hidden',
                borderRadius: theme => theme.shape.borderRadius,
                margin: 2,
                boxShadow: theme => theme.shadows[4],
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: theme => `linear-gradient(to bottom, ${theme.palette.gradients.primary})`,
                    opacity: 0.85,
                    zIndex: 1,
                }
            }}
            component="section"
        >
            {/* Background Image */}
            <Box
                component="img"
                src="/assets/celebration.jpg"
                alt="Event Hero"
                sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center 30%',
                    transition: theme => theme.transitions.create('transform', {
                        duration: theme.transitions.duration.standard,
                    }),
                }}
            />

            {/* Full Background Overlay */}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'rgba(0, 0, 0, 0.4)', // Using text.primary color with transparency
                    backdropFilter: 'blur(2px)',
                    zIndex: 1,
                }}
            />

            {/* Content */}
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 2,
                    textAlign: 'center',
                    width: '90%',
                    maxWidth: '900px',
                    padding: 4,
                }}
            >
                <Box
                    component="img"
                    src="/assets/logo1.svg"
                    alt="Eventa Logo"
                    sx={{
                        width: {
                            xs: '200px',  // Smaller for mobile
                            sm: '240px',  // Medium size for tablets 
                            md: '280px',  // Desktop size
                            lg: '320px'   // Large desktop size
                        },
                        height: 'auto',   // Let height scale proportionally
                        maxWidth: '75vw', // Slightly more constrained width
                        marginBottom: { xs: 3, sm: 4, md: 5 },
                        display: 'block',
                        margin: '0 auto',
                        transformOrigin: 'center center',
                        filter: theme => `
                            drop-shadow(${theme.shadows[4]})
                            brightness(1.15)
                            contrast(1.1)
                        `,
                        transition: theme => theme.transitions.create(
                            ['filter', 'transform'],
                            {
                                duration: theme.transitions.duration.standard,
                                easing: theme.transitions.easing.easeInOut,
                            }
                        ),
                        animation: 'fadeIn 1.2s ease-out',
                        '@keyframes fadeIn': {
                            from: {
                                opacity: 0,
                                transform: 'scale(0.8) translateY(20px)'
                            },
                            to: {
                                opacity: 1,
                                transform: {
                                    xs: 'scale(1.2)',
                                    sm: 'scale(1.3)',
                                    md: 'scale(1.4)',
                                    lg: 'scale(1.5)'
                                }
                            }
                        },
                        transform: {
                            xs: 'scale(1.2)',
                            sm: 'scale(1.3)',
                            md: 'scale(1.4)',
                            lg: 'scale(1.5)'
                        },
                        '&:hover': {
                            filter: theme => `
                                drop-shadow(${theme.shadows[8]})
                                brightness(1.25)
                                contrast(1.15)
                            `,
                            transform: {
                                xs: 'scale(1.3)',
                                sm: 'scale(1.4)',
                                md: 'scale(1.5)',
                                lg: 'scale(1.6)'
                            },
                            transition: theme => theme.transitions.create(
                                ['filter', 'transform'],
                                {
                                    duration: '0.4s',
                                    easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
                                }
                            ),
                        }
                    }}
                />
                <Typography 
                    variant="h4"
                    sx={{
                        color: theme => theme.palette.secondary.main,
                        textShadow: theme => `2px 2px 4px ${theme.palette.background.overlay}`,
                        fontSize: { 
                            xs: '1.5rem',
                            md: '2rem' 
                        },
                        fontWeight: 600,
                        letterSpacing: 0.5,
                    }}
                >
                    איוונטה אישורי הגעה - כמה פשוט, ככה חכם!
                </Typography>
            </Box>
        </Box>
    );
}

export default HeroSection;
