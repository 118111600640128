// src/components/LandingPage/LandingPage.js

import React, { useState, useEffect } from 'react';
import {
    Container,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Snackbar,
    Alert,
    Button,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import theme from '../../theme';
import './LandingPage.css';

// Import Components
import Navbar from './Navbar';
import HeroSection from './HeroSection';
import AboutUsSection from './AboutUsSection';
import DemoVideoSection from './DemoVideoSection';
import KeyFeaturesSection from './KeyFeaturesSection';
import PriceSection from './PriceSection';
import FAQSection from './FAQSection';
import ContactUsSection from './ContactUsSection';
import Footer from './Footer';

function LandingPage() {
    const [openTokenModal, setOpenTokenModal] = useState(false);
    const [token, setToken] = useState('');
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success',
    });
    const [drawerOpen, setDrawerOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Eventa - אישורי הגעה';
    }, []);

    // Function to handle opening the token modal
    const handleOpenTokenModal = () => {
        setOpenTokenModal(true);
    };

    // Function to handle closing the token modal
    const handleCloseTokenModal = () => {
        setOpenTokenModal(false);
        setToken('');
    };

    // Function to handle token submission
    const handleTokenSubmit = () => {
        if (token.trim() === '') {
            setSnackbar({
                open: true,
                message: 'אנא הזן את הטוקן הייחודי שלך.',
                severity: 'error',
            });
            return;
        }

        navigate(`/event?token=${token.trim()}`);
        setOpenTokenModal(false);
        setToken('');
    };

    // Function to handle snackbar close
    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    // Function to toggle the drawer
    const toggleDrawer = (open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setDrawerOpen(open);
    };

    // Function to handle opening the sign-in modal (now opens Token Modal)
    const handleSignIn = () => {
        handleOpenTokenModal();
    };

    return (
        <React.Fragment>
            {/* Skip Navigation Link */}
            <a href="#main-content" className="skip-link">
                דלג לתוכן הראשי
            </a>

            {/* SEO Meta Tags */}
            <Helmet>
                <title>Eventa - אישורי הגעה</title>
                <meta name="description" content="Eventa RSVP הוא הפתרון האולטימטיבי לניהול אירועים ואישורי הגעה של האורחים שלך. צור הזמנות דיגיטליות, עקוב אחר סטטיסטיקות, ועוד." />
                <meta name="keywords" content="אירועים, RSVP, ניהול אירועים, הזמנות דיגיטליות, דוחות אירועים" />
                <meta name="author" content="Eventa RSVP" />
                {/* Open Graph Tags for Social Media */}
                <meta property="og:title" content="Eventa RSVP - הפתרון האולטימטיבי לניהול אירועים" />
                <meta property="og:description" content="Eventa RSVP הוא הפתרון האולטימטיבי לניהול אירועים ואישורי הגעה של האורחים שלך. צור הזמנות דיגיטליות, עקוב אחר סטטיסטיקות, ועוד." />
                <meta property="og:image" content="/assets/celebration.jpg" />
                <meta property="og:url" content="https://www.eventa-rsvp.com" />
                <meta property="og:type" content="website" />
            </Helmet>

            {/* Snackbar for Notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>

            <ThemeProvider theme={theme}>
                <Box
                    sx={{
                        minHeight: '100vh',
                        backgroundColor: theme.palette.background.default,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {/* Navigation Bar */}
                    <Navbar toggleDrawer={toggleDrawer} drawerOpen={drawerOpen} handleSignIn={handleSignIn} />

                    <main id="main-content">
                        <Container maxWidth="lg" sx={{ flexGrow: 1, pt: 10 }}>
                            {/* Hero Section */}
                            <HeroSection />

                            {/* Demo Video Section */}
                            <DemoVideoSection />

                            {/* Key Features Section */}
                            <KeyFeaturesSection />

                            {/* Price Section */}
                            <PriceSection />

                            {/* About Us Section */}
                            <AboutUsSection />

                            {/* FAQ Section */}
                            <FAQSection />

                            {/* Contact Us Section */}
                            <ContactUsSection setSnackbar={setSnackbar} />
                        </Container>
                    </main>

                    {/* Footer */}
                    <Footer />
                </Box>

                {/* Token Prompt Modal */}
                <Dialog open={openTokenModal} onClose={handleCloseTokenModal}>
                    <DialogTitle>הזן את הטוקן הייחודי שלך</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="טוקן ייחודי"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={token}
                            onChange={(e) => setToken(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleTokenSubmit();
                                }
                            }}
                            aria-label="טוקן ייחודי"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseTokenModal}>ביטול</Button>
                        <Button onClick={handleTokenSubmit} variant="contained">
                            התחבר
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </React.Fragment>)
}

export default LandingPage;
