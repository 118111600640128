// src/components/ErrorScreen.js
import React from "react";
import { Container, Typography, Box } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ErrorScreen = ({ errorMessage }) => {
    return (
        <Container maxWidth="sm">
            <Box
                sx={(theme) => ({
                    textAlign: "center",
                    mt: 8,
                    p: 4,
                    borderRadius: theme.shape.borderRadius * 2,
                    background: theme.palette.background.paper,
                    boxShadow: theme.shadows[2],
                    border: `1px solid ${theme.palette.divider}`,
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        boxShadow: theme.shadows[4],
                        transform: 'translateY(-2px)',
                    }
                })}
            >
                <ErrorOutlineIcon 
                    sx={(theme) => ({
                        fontSize: '3rem',
                        color: theme.palette.error.main,
                        mb: 2,
                        opacity: 0.8,
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                            opacity: 1,
                            transform: 'scale(1.05)',
                        }
                    })}
                />
                <Typography 
                    variant="h5"
                    sx={(theme) => ({
                        color: theme.palette.text.primary,
                        mb: 2,
                        fontWeight: 500,
                        fontFamily: theme.typography.fontFamily
                    })}
                >
                    שגיאה
                </Typography>
                <Typography 
                    variant="body1"
                    sx={(theme) => ({
                        color: theme.palette.text.secondary,
                        maxWidth: '90%',
                        mx: 'auto',
                        lineHeight: 1.6,
                        fontFamily: theme.typography.fontFamily
                    })}
                >
                    {errorMessage}
                </Typography>
            </Box>
        </Container>
    );
};

export default ErrorScreen;
