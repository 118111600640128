// src/components/LandingPage/Footer.js

import React from 'react';
import { Box, Typography, Container } from '@mui/material';

function Footer() {
    return (
        <Box 
            component="footer" 
            sx={{ 
                py: 3,
                px: 2,
                mt: 'auto',
                background: theme => theme.palette.background.gradient,
                borderTop: theme => `1px solid ${theme.palette.divider}`,
                backdropFilter: 'blur(8px)',
            }}
        >
            <Container maxWidth="lg">
                <Typography 
                    variant="body2" 
                    align="center"
                    sx={{
                        color: 'text.secondary',
                        fontWeight: theme => theme.typography.body2.fontWeight,
                        '& strong': {
                            color: 'primary.main',
                            fontWeight: 500
                        }
                    }}
                >
                    &copy; {new Date().getFullYear()} <strong>Eventa RSVP</strong>. כל הזכויות שמורות.
                </Typography>
            </Container>
        </Box>
    );
}

export default Footer;
