// src/components/LandingPage/DemoVideoSection.js

import React, { useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { motion, useInView } from 'framer-motion';

function DemoVideoSection() {
    const videoRef = useRef(null);
    const isInView = useInView(videoRef, { once: true, amount: 0.3 });

    // Start playing when the video comes into view
    React.useEffect(() => {
        if (isInView && videoRef.current) {
            // Try to play with sound
            videoRef.current.muted = false;
            videoRef.current.play().catch(error => {
                console.log("Autoplay with sound failed, trying muted:", error);
                // If failed, try muted autoplay as fallback
                videoRef.current.muted = true;
                videoRef.current.play().catch(mutedError => {
                    console.log("Muted autoplay also failed:", mutedError);
                });
            });
        }
    }, [isInView]);

    return (
        <Box 
            id="demo-video" 
            component="section"
            sx={{ 
                mt: 0,
                mb: 0, 
                textAlign: 'center',
                background: 'transparent',
                py: 6,
                px: 2
            }}
        >
            <Typography 
                variant="h4" 
                gutterBottom
                sx={{
                    color: 'primary.main',
                    mb: 4,
                    fontWeight: theme => theme.typography.h4.fontWeight
                }}
            >
                סרטון הדגמה
            </Typography>
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
            >
                <Box 
                    sx={{ 
                        position: 'relative', 
                        paddingTop: '56.25%', /* 16:9 Aspect Ratio */
                        maxWidth: '1200px', // Increased from 800px
                        margin: '0 auto',
                        borderRadius: theme => theme.shape.borderRadius,
                        overflow: 'hidden',
                        boxShadow: theme => theme.shadows[4],
                        transition: theme => theme.transitions.create(['box-shadow']),
                        '&:hover': {
                            boxShadow: theme => theme.shadows[8]
                        }
                    }}
                >
                    <video
                        ref={videoRef}
                        playsInline
                        loop
                        controls // Added controls for manual sound toggle
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain', // Changed from 'cover' to 'contain'
                            backgroundColor: 'black', // Added to fill empty space
                        }}
                    >
                        <source src="/assets/eventa_demo.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Box>
            </motion.div>
        </Box>
    );
}

export default DemoVideoSection;
